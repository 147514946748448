import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CardElement,
  useStripe,
  useElements,
  PaymentRequestButtonElement
} from "@stripe/react-stripe-js";
import API from "../../Services/API";
import { Button, TextField, IconButton } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Time from '../../Images/TimeGreen.svg'
import CarSVG from '../../Images/Car.svg'
import Dollar from '../../Images/Dollar.svg'
import LocArrowsvg from '../../Images/locationarrow-svg.svg'
import VisaSelected from '../../Images/visa-selected.png'
import MasterSelected from '../../Images/master-selected.png'
import AmericanSelected from '../../Images/american-selected.png'
import DefaultSelection from '../../Images/cardPayment.png'
import SearchComponent from "../../Components/Search/Search";
import PopUpView from "../../Components/Popup/PopUpView";
import { useSelector } from "react-redux";
import Moment from 'react-moment';
import moment from 'moment';
import Back from '../../Images/back.png';
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { decodeJWT } from "../../Common/utils";

import { useDispatch } from "react-redux";
import { DataShareService, DataShareType } from "../../Common/DataService";
import Refresh from "../../Common/Refresh";

export default function CheckoutForm(_props) {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const navigate = useNavigate();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [spinner, SetSpinner] = useState(false);
  const [screenName, setScreenName] = useState("card")
  const [alertMessage, setAlertMessage] = useState("")
  const [showConfirm, setShowConfirm] = useState(location.state.PaymentIntent ? true : false);
  const state = useSelector((state) => state);
  const currentDate = moment().format("MM/DD/YYYY");
  const [ReceiptPopup, setReceiptPopup] = useState(true)
  const expireDate = moment.utc(location.state.bookingEndtime ? location.state.bookingEndtime : "").format("MM/DD/YYYY");
  const [bookingEndTime, setBookingEndTime] = useState(location.state.bookingEndtime ? location.state.bookingEndtime : "")
  const [vehicleName, setVehicleName] = useState(`${state.user.VehicleDetails.make} ${state.user.VehicleDetails.model}`)
  const [licensePlate, setlicensePlate] = useState(`${state.user.VehicleDetails.licensePlate}`)
  const [duration, setDuration] = useState(15);
  const [durationType, setDurationType] = useState('Minute');
  const [durationData, setDurationData] = useState(`${state.user.DurationDetails.Duration} ${state.user.DurationDetails.DurationType} `)
  const [totalPrice, setTotalPrice] = useState(location.state.totalPrice);
  const [totalPriceApi, setTotalPriceApi] = useState(location.state.totalPriceApi);
  const [cardName, setCardName] = useState("");
  const [last4, setLast4] = useState("");
  const [expMonth, setExpMonth] = useState("");
  const [expYear, setExpYear] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [priceWithTax, setPriceWithTax] = useState(location.state.priceWithTax);
  const [isFreeBooking, setIsFreeBooking] = useState(location.state.PaymentIntent ? true : false);
  const [freePaymentIntent, setFreePaymentIntent] = useState(location.state.PaymentIntent);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPayParkDisabled, setIsPayParkDisabled] = useState(false);
  const [showPaymentStatusFailed, setShowPaymentStatusFailed] = useState(false);
  const [showNoPaymentStatusYetAlert, SetShowNoPaymentStatusYetAlert] = useState(false);
  const [PSFailedAfterCheckNowAlert, setPSFailedAfterCheckNowAlert] = useState(false);
  const [PSSuccessAfterCheckNowAlert, setPSSuccessAfterCheckNowAlert] = useState(false);
  const [tempFriendlySpotId, setTempFriendlySpotId] = useState("");
  ;

  const [paymentRequest, setPaymentRequest] = useState(null);
  const dispatch = useDispatch();
  const { addVehicle } = bindActionCreators(actionCreators, dispatch);
  const { addDuration } = bindActionCreators(actionCreators, dispatch);
  const appInsights = useAppInsightsContext();
  const [tempPayload, setTempPayload] = useState({});
  var CheckPaymentIntentID = ""
  var tiggerUseEffectTimes = 0;
  var triggerPayment = false;
  const api = new API()

  const cardStyle = {
    style: {
      base: {
        color: "#111012",
        fontFamily: 'sans-serif, Manrope',
        fontSize: "16px",
        "::placeholder": {
          color: "#6B7278"
        }
      },
      // invalid: {
      //   fontFamily: 'Manrope',
      //   color: "#fa755a",
      //   iconColor: "#fa755a"
      // }
    }
  };
  const addToAzureAppInsight = (Payload, Type) => {
    try {
      var Payload = Payload;
      var StoreType = Type ? Type : 'Basic'
      if (Payload["User_Guid"] == "") {
        var userguid = decodeJWT(String(localStorage.getItem('Stored-AccessToken')), 'userguid');
        Payload["User_Guid"] = userguid;
      }
      if (StoreType == 'Basic') {
        appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
      }
      if (process.env.REACT_APP_APP_INSIGHT_TYPE == 'Detail' && StoreType == 'Detail') {
        appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
      }
    }
    catch (err) {
      console.log(err);
    }

  }
  useEffect(() => {
    addToAzureAppInsight({ User_Guid: "", Action: "current-screen-card", Page: "CardScreen" })
  }, [])
  useEffect(() => {
    if (showConfirm) {
      addToAzureAppInsight({ User_Guid: "", Action: "current-screen-payment-confirm", Page: "PaymentConfirm" })
    }
  }, [showConfirm])

  //Google pay V1
  useEffect(() => {
    // console.log("/Google Inc/",/Google Inc/.test(navigator.vendor));
    // console.log("/Android",/Google Inc/.test(navigator.vendor));
    // console.log("iOS",/iOS/(iPhone|iPad|iPod)/.test(navigator.platform))

    // if (/Google Inc/.test(navigator.vendor) && /Android/.test(navigator.userAgent)) {
    PaymentRequestButton()
    // }

    // console.log("/Google Inc/.test(navigator.vendor),", /Google Inc/.test(navigator.vendor))
  }, [stripe]);

  useEffect(() => {
    return () => {
      DataShareService.subscribe(DataShareType.PaymentCompleted, () => {
        setShowPaymentStatusFailed(false);
      })
    };
  }, []);

  const PaymentRequestButton = async () => {
    if (!stripe) {

      return;
    }
    // console.log("yes stripe", totalPrice);
    // console.log("parseInt(totalPrice)", parseInt(totalPrice));
    // sessionStorage.setItem('Temp-blockGuid',null);
    const pr = stripe.paymentRequest({
      currency: 'cad',
      country: 'CA',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: 'Payment',
        amount: Number(totalPrice) * 100,
      },
    })


    pr.canMakePayment().then((result) => {
      // console.log("result", result);
      setTimeout(() => {
        if (result) {
          addToAzureAppInsight({ User_Guid: "", Action: `payment-request-button-visible`, Page: "CardScreen" });
        } else {
          addToAzureAppInsight({ User_Guid: "", Action: `payment-request-button-not-visible`, Page: "CardScreen" });
          //console.log("if case result", result );
        }
      }, 1000);


      if (result) {
        setPaymentRequest(pr);
        pr.on('token', ((event) => {
          pr.on('paymentmethod', ((eventv2) => {
            // console.log("Session stored Temp-blockGuid set to null");
            sessionStorage.setItem('Temp-blockGuid', 'null');
            let paymentIntentPostData = {
              "TemporaryBlockGuid": location.state.blockGuid
            }
            // console.log("paymentIntentPostData", paymentIntentPostData);
            api.initiate_payment(
              paymentIntentPostData
              ,
              {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
              }
            ).then((initiate_payment) => {

              // console.log("initiate_payment response", initiate_payment);
              if (initiate_payment != 'non200') {
                // console.log("string", JSON.stringify(initiate_payment));
                let resstring = JSON.stringify(initiate_payment);
                // console.log("resstring", resstring.includes('pi'));
                handleParentSpinner(true);
                if (resstring.includes('pi')) {
                  stripe.confirmCardPayment(initiate_payment, {
                    payment_method: eventv2.paymentMethod.id
                  }).then((res) => {
                    // console.log("confirm payment response", res.paymentIntent.id);
                    if (res.paymentIntent.id) {
                      setTimeout(() => {
                        addToAzureAppInsight({ User_Guid: "", Action: `payment-request-payment-intent-trigged`, Page: "CardScreen" }, 'Detail');
                        addToAzureAppInsight({ User_Guid: "", Action: `payment-request-check-payment-repeat`, Page: "CardScreen" }, 'Detail');
                      }, 1000);
                      event.complete('success');
                      checkPaymentStatusRepeat(res);
                    } else {
                      event.complete('fail');
                      handleParentAlert(res.error.message);
                    }
                  }
                  )
                } else if (resstring.includes('FREE')) {
                  event.complete('success');
                  setFreePaymentIntent(initiate_payment)
                  setIsFreeBooking(true);

                  // setTimeout(() => {
                  payment_status(initiate_payment)
                  // }, 10000);

                }

              }
              else {
                event.complete('fail');
                handleParentSpinner(false);
                setTimeout(() => {
                  // console.log("alert should throw");
                  handleParentAlert("Sorry, we are unable to book your spot at this time. Please try again later.");
                }, 1000);
              }


            });
            // }

            // })

          }));

        }));
        // console.log("@@@@@@@@@@@ after@@@@@@@@@@@@@")
      }
    });
  }

  useEffect(() => {

    _props.handleParentSpinner(spinner)
    _props.handleParentTitle(screenName)
    _props.handleParentAlert(alertMessage);


  }, [spinner])


  useEffect(() => {

    //console.log("state.user.VehicleDetails", state.user.VehicleDetails)
  })

  const handleParentSpinner = (value) => {
    SetSpinner(value);
  }
  const handleParentTitle = (value) => {
    setScreenName(value);
  }
  const handleParentAlert = (value) => {
    setAlertMessage(value);
  }

  const handleChange = async (event) => {

    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    // console.log("event", event);
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleConfirmPay = async (ev) => {
    setIsPayParkDisabled(true);
    handleParentSpinner(true);
    localStorage.setItem("Payment_Background_check", false);
    //location.state.blockGuid
    sessionStorage.setItem('Temp-blockGuid', 'null');
    addToAzureAppInsight({ User_Guid: "", Action: 'pay-and-park-button', Page: "PaymentConfirm" });
    if (!isFreeBooking) {
      const PaymentIntent = await api.initiate_payment({
        "TemporaryBlockGuid": location.state.blockGuid,
        "PaymentCardId": paymentId
      },

        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': location.state.AccessToken
        }
      )
      // const PaymentIntent = PaymentIntent_raw.response
      //const PaymentIntent = PaymentIntent_raw.json()
      // console.log("initiate_payment", PaymentIntent);
      // if (typeof (PaymentIntent) === "string") {
      if (PaymentIntent != 'non200') {
        if (JSON.stringify(PaymentIntent).includes('FREE')) {
          payment_status(PaymentIntent)
        } else {
          const payload = await stripe.confirmCardPayment(PaymentIntent, {
            payment_method: paymentId
          });

          // call only after success. -> success 
          // console.log("confirmCardPayment", payload);
          if (payload.error) {
            handleParentSpinner(false);
            let error = payload.error
            handleParentAlert(`${error.message}`)
          } else {
            checkPaymentStatusRepeat(payload)
          }


        }
      }
      else {
        handleParentSpinner(false);
        handleParentAlert("Sorry, we are unable to book your spot at this time. Please try again later.");

      }





    } else {
      setTimeout(() => {
        payment_status(freePaymentIntent)
      }, 10000);
    }
  }
  const checkPaymentStatusRepeat = async (payload) => {
    storeLastBookingDetails();
    sessionStorage.setItem("access-token", location.state.AccessToken);
    localStorage.setItem("pending-payment-id", payload.paymentIntent.id);
    let checkTime = []
    for (let i = 1; i <= 60; i++) {
      checkTime.push(moment().add(i*0.5, 'minutes'))
    }
    localStorage.setItem('Stored-checkTime', JSON.stringify(checkTime));
    for (var i = 0; i < 30; i++) {
      if (i == 9 || i == 19 || i == 28) {
        payment_status(payload)
      } else {
        const no_final_payment_status = await api.no_final_payment_status(isFreeBooking ? freePaymentIntent : payload.paymentIntent.id, {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': location.state.AccessToken
        })
        if (no_final_payment_status.reservationGuid) {
          addToAzureAppInsight({

            User_Guid: "", Action: `check-payment-repeat-success`, Page: showConfirm ? "PaymentConfirm" : "CardScreen", Payload: {
              SuccessLoopTime: `${i}`
            }
          }, 'Detail');
          triggerPayment = false;
          payment_status(payload)
          return
        } else {
          if (i == 5) {
            handleParentSpinner(false)
            handleParentAlert("show-alert-BG");
            setTimeout(() => {
              handleParentAlert("show-alert-BG");
              SetShowNoPaymentStatusYetAlert(true);
            }, 500);
          }
          if (i == 29) {

            handleParentAlert("hide-alert-BG");
            SetShowNoPaymentStatusYetAlert(false);
            storeLastBookingDetails();
            sessionStorage.setItem("access-token", location.state.AccessToken);
            localStorage.setItem("pending-payment-id", payload.paymentIntent.id);
            handleParentSpinner(false)
            if (error.message) {
              handleParentAlert(`${error.message}`)
            }
            handleParentAlert("show-alert-BG");
            setShowPaymentStatusFailed(true);
            addToAzureAppInsight({ User_Guid: "", Action: "payment-status-failed-alert-throw", Page: showConfirm ? "PaymentConfirm" : "CardScreen" });
            triggerPayment = false;

            DataShareService.subscribe(DataShareType.PaymentCompleted, () => {
              // console.log("tigger result unSubscribe ")

              setTimeout(() => {
                setShowPaymentStatusFailed(false);
                handleParentAlert("hide-alert-BG");
                addVehicle({});
                var DurationDetails = {
                  Duration: 0,
                  DurationType: "Minutes"
                }
                addDuration(DurationDetails);
                navigate("/");

              }, 1000);

            })
            setTempPayload(payload);
            // console.log("current time", moment().add(1, 'minutes').format("MM ddd, YYYY hh:mm:ss a"));
            let checkTime = []
            for (let i = 1; i <= 60; i++) {
              checkTime.push(moment().add(i, 'minutes'))
            }
            localStorage.setItem('Stored-checkTime', JSON.stringify(checkTime));


            // console.log("check time", checkTime);
            // console.log()
            //here to write logic



            // handleParentAlert("Sorry, we are facing issue in booking your spot. Please try again later");

          }

        }
      }

      await new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve()

        }, 1000);
      })
      // console.log("no_final_payment_status", no_final_payment_status);
    }
  }
  const PaymentStatusFailedAlert = () => {

    return (
      (
        showPaymentStatusFailed && (
          <div className="CustAlert_Screen">
            <div className="Custpopup">
              <h1 className="Title">
                GrydPark
              </h1>
              {
                <text className="SubTitle">
                  Sorry, this is taking longer than expected. You can continue to wait or safely close this message and we will notify you once the transaction is complete.
                </text>
              }
              <div style={{ 'marginTop': "20px" }} className="DualButton">
                {/* <div style={{ width: '45%' }}>
                  <Button style={{ width: '100%', height: '58px', backgroundColor: '#FFFFFF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="outlined"
                    onClick={async () => {
                      var cpyPayload = tempPayload
                      addToAzureAppInsight({ User_Guid: "", Action: `check-payment-status-again-via-alert`, Page: showConfirm ? "PaymentConfirm" : "CardScreen" });
                      const payment_status_res = await api.payment_status(cpyPayload.paymentIntent.id, {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': location.state.AccessToken
                      })
                      // console.log("payment_status_res", payment_status_res);
                      if (payment_status_res.friendlySpotId) {
                        addToAzureAppInsight({ User_Guid: "", Action: `success-on-check-payment-status-again-via-alert`, Page: showConfirm ? "PaymentConfirm" : "CardScreen" }, 'Detail');
                        setShowPaymentStatusFailed(false);
                        setPSSuccessAfterCheckNowAlert(true);
                        PaymentStatusSuccessAfterCheckNowAlert(payment_status_res.friendlySpotId, cpyPayload)
                        setTempFriendlySpotId(payment_status_res.friendlySpotId);
                        localStorage.removeItem('Stored-checkTime');
                      } else {
                        setPSFailedAfterCheckNowAlert(true);
                        addToAzureAppInsight({ User_Guid: "", Action: `fail-again-Check-Payment-status-again-via-alert`, Page: showConfirm ? "PaymentConfirm" : "CardScreen" }, 'Detail');

                      }
                    }}>
                    Check Now
                  </Button>
                </div> */}
                <div style={{ width: '45%' }}>
                  <Button style={{ width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                    onClick={() => {
                      //here wirte logic repeat 
                      setShowPaymentStatusFailed(false);
                      handleParentAlert("hide-alert-BG");
                      addVehicle({});
                      var DurationDetails = {
                        Duration: 0,
                        DurationType: "Minutes"
                      }
                      addDuration(DurationDetails);
                      addToAzureAppInsight({ User_Guid: "", Action: `close-check-payment-status-again-via-alert`, Page: showConfirm ? "PaymentConfirm" : "CardScreen" }, 'Detail');
                      navigate("/");

                    }}>
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      )
    )
  }
  const NoPaymentStatusYetAlert = () => {

    return (
      (
        showNoPaymentStatusYetAlert && (
          <div className="CustAlert_Screen" >
            <div className="Custpopup" style={{ 'height': '200px' }}>
              <h1 className="Title">
                Processing transaction
              </h1>
              {
                <text className="SubTitle" style={{ 'height': '50px' }}>
                  Please do not close this tab. It may take around 30 seconds to complete
                </text>
              }
              <div style={{ 'marginTop': "0px" }} className="DualButton">
                <CircularProgress />
              </div>
            </div>
          </div>
        )
      )
    )
  }

  const PaymentStatusFailedAfterCheckNowAlert = () => {

    return (
      (
        PSFailedAfterCheckNowAlert && (
          <div className="CustAlert_Screen">
            <div className="Custpopup">
              <h1 className="Title">
                GrydPark
              </h1>
              {
                <text className="SubTitle">
                  Sorry, we didn't receive your payment yet.
                </text>
              }
              <div className="SingleButton">
                <Button style={{ width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                  onClick={() => {
                    setPSFailedAfterCheckNowAlert(false);
                  }}>
                  {"Okay"}
                </Button>
              </div>
            </div>
          </div>
        )
      )
    )
  }
  const PaymentStatusSuccessAfterCheckNowAlert = (friendlySpotId, payload) => {
    // console.log("In func friendlySpotId", friendlySpotId);
    // console.log("In func payload", payload);

    return (
      (
        PSSuccessAfterCheckNowAlert && (
          <div className="CustAlert_Screen">
            <div className="Custpopup">
              <h1 className="Title">
                GrydPark
              </h1>
              {
                <text className="SubTitle">
                  `Hey! We have got your payment, and we have reserved ${tempFriendlySpotId} spot for you. Do you want to navigate to view and download booking receipt?`
                </text>
              }
              <div style={{ 'marginTop': "20px" }} className="DualButton">
                <div style={{ width: '45%' }}>
                  <Button style={{ width: '100%', height: '58px', backgroundColor: '#FFFFFF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="outlined"
                    onClick={async () => {
                      addToAzureAppInsight({ User_Guid: "", Action: `navigate-to-receipt-via-alert`, Page: showConfirm ? "PaymentConfirm" : "CardScreen" }, 'Detail');
                      payment_status(tempPayload);

                    }}>
                    Yes
                  </Button>
                </div>
                <div style={{ width: '45%' }}>
                  <Button style={{ width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                    onClick={() => {
                      addToAzureAppInsight({ User_Guid: "", Action: `navigate-to-vehicle-via-alert`, Page: showConfirm ? "PaymentConfirm" : "CardScreen" }, 'Detail');
                      setPSSuccessAfterCheckNowAlert(false);
                      setShowPaymentStatusFailed(false);
                      handleParentAlert("hide-alert-BG");
                      navigate("/");
                      addVehicle({});
                      var DurationDetails = {
                        Duration: 0,
                        DurationType: "Minutes"
                      }
                      addDuration(DurationDetails);


                    }}>
                    No
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      )
    )
  }



  const storeLastBookingDetails = () => {
    var lastBookingDetails = {
      "propertyName": state.user.VehicleDetails.propertyName,
      "make": state.user.VehicleDetails.make,
      "model": state.user.VehicleDetails.model,
      "color": state.user.VehicleDetails.color,
      "licensePlate": state.user.VehicleDetails.licensePlate,
      "propertyGuid": state.user.VehicleDetails.propertyGuid,
      "curLatitude": state.user.VehicleDetails.curLatitude,
      "curLongitude": state.user.VehicleDetails.curLongitude,
      "phoneNumber": state.user.VehicleDetails.phoneNumber
    }
    localStorage.setItem('last-booking-details', JSON.stringify(lastBookingDetails));
  }

  const payment_status = async (payload) => {


    var triggerFreeV2 = JSON.stringify(payload).includes('FREE')

    const ConfirmPayment = await api.payment_status(isFreeBooking ? freePaymentIntent : triggerFreeV2 ? payload : payload.paymentIntent.id, {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': location.state.AccessToken
    })



    if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
      handleParentAlert("hide-alert-BG");
      SetShowNoPaymentStatusYetAlert(false);
      handleParentSpinner(false);
      storeLastBookingDetails();
      //
      localStorage.removeItem("pending-payment-id");
      localStorage.removeItem('Stored-checkTime');

      if (ConfirmPayment.spotGuid && ConfirmPayment.reservationGuid) {
        localStorage.setItem('Stored-spotGuid', ConfirmPayment.spotGuid);
        localStorage.setItem('Stored-reservationGuid', ConfirmPayment.reservationGuid);
      }
      const value = {
        friendlySpotId: ConfirmPayment.friendlySpotId,
        friendlyReservationId: ConfirmPayment.friendlyReservationId,
        reservationGuid: ConfirmPayment.reservationGuid
      }
      if (isFreeBooking) {
        addToAzureAppInsight({
          User_Guid: "", Action: `free-booking`, Page: showConfirm ? "PaymentConfirm" : "CardScreen", Payload: {
            'FriendlySpotId': `${ConfirmPayment.friendlySpotId}`
          }
        }, 'Detail');
      } else {
        addToAzureAppInsight({
          User_Guid: "", Action: `normal-booking`, Page: showConfirm ? "PaymentConfirm" : "CardScreen", Payload: {
            'FriendlySpotId': `${ConfirmPayment.friendlySpotId}`
          }
        }, 'Detail');
      }
      _props.handleReceiptPopUp(value)

      // navigate('/receipt', {
      //   state:
      //   {
      //     friendlySpotId: ConfirmPayment.friendlySpotId,
      //     friendlyReservationId: ConfirmPayment.friendlyReservationId,
      //     reservationGuid: ConfirmPayment.reservationGuid
      //   }
      // }
      // );
    }

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      addToAzureAppInsight({
        User_Guid: "", Action: `error-payment-at-final-status`, Page: showConfirm ? "PaymentConfirm" : "CardScreen", Payload: {
          'error': `${payload.error.message}`
        }
      }, 'Detail');

      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }

  }

  const handleSubmit = async (ev) => {
    console.log("handleSubmit", ev);
    addToAzureAppInsight({ User_Guid: "", Action: 'next-button', Page: "CardScreen" });
    if (!stripe) {
      addToAzureAppInsight({ User_Guid: "", Action: 'no-stripe', Page: "CardScreen" }, 'Detail');
      return;
    }
    else {
      handleParentSpinner(true);
      handleParentAlert(`dnt-call-Alert`);
      ev.preventDefault();
      setProcessing(true);
      setIsNextDisabled(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
      handleParentSpinner(false);
      if (paymentMethod) {
        if (paymentMethod.card) {
          if (paymentMethod.card.brand) {
            setCardName(paymentMethod.card.brand);
          }
          if (paymentMethod.card.last4) {
            setLast4(paymentMethod.card.last4);
          }
          if (paymentMethod.card.exp_month) {
            setExpMonth(paymentMethod.card.exp_month);
          }
          if (paymentMethod.card.exp_year) {
            setExpYear(paymentMethod.card.exp_year);
          }
          if (paymentMethod.id) {
            setPaymentId(paymentMethod.id);
            localStorage.setItem('Stored-PaymentId', paymentMethod.id);
          }

          addToAzureAppInsight({ User_Guid: "", Action: 'stripe-create-payment', Page: "CardScreen" }, 'Detail');
          handleParentTitle("ConfirmParking");
          setShowConfirm(true)
        }
      }
      else {
        setIsNextDisabled(false);
        console.log("error", error);
        handleParentAlert(`${error.message}`);
        addToAzureAppInsight({
          User_Guid: "", Action: `error-create-payment`, Page: "CardScreen", Payload: {
            'Error': `${error.message}`
          }
        });
        //here to write card error
      }
    }


  };


  return (

    <div>
      {
        PaymentStatusFailedAlert()
      }
      {
        PaymentStatusFailedAfterCheckNowAlert()
      }
      {
        PaymentStatusSuccessAfterCheckNowAlert()
      }
      {
        NoPaymentStatusYetAlert()
      }

      <meta name="viewport" content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no" />

      {
        showConfirm && (
          <div>
            <div>

            </div>
            <IconButton style={{ backgroundColor: 'transparent' }} onClick={
              () => {
                if( !isFreeBooking){
                  setShowConfirm(false);
                }else{
                  navigate(-1)
                }
              
                setIsNextDisabled(false);
              }}
            >
              <img className="Back-Image" src={Back}></img> <small className="BackButtonText"
              >Back</small>
            </IconButton>
            <div>
              <p className="SearchTitle"> Confirm Parking </p>
              <div className="SearchPropertyInputView-NonEdit">

                {/* </IconButton> */}
                <div style={{ alignSelf: 'center', margin: '8px' }}>
                  <img className="Arrow-Image" src={LocArrowsvg}></img>
                </div>
                <input className="SearchInput" type="text" name="Address" placeholder="Enter Your Location" value={state.user.VehicleDetails.propertyName} readOnly />

              </div>

              <div style={{ width: '100%', marginTop: '20px' }} className='Vehicle-InputView'>
                <div className="NonEditableView">
                  <div style={{ alignSelf: 'center', margin: '8px' }}>
                    <img style={{ height: '25px', width: '25px' }} src={CarSVG}></img>
                  </div>
                  <div>
                    <p className='LogoExpTime'>{vehicleName} <br />{licensePlate} </p>
                  </div>
                </div>
                <div className="NonEditableView">
                  <div style={{ alignSelf: 'center', margin: '8px' }}>
                    <img style={{ height: '25px', width: '25px' }} src={Time}></img>
                  </div>
                  <div className='CardStuff'>
                    <div>
                      <p className='LogoExpTime'>{`${durationData}`} <br /> {currentDate == expireDate ? `Expires today at ${moment.utc(bookingEndTime).format('hh:mm A')} ` : `Expires on ${moment.utc(bookingEndTime).format('DD/MM/YYYY hh:mm A')}`}</p>
                    </div>
                  </div>
                </div>
                <div className="NonEditableView">
                  <div style={{ alignSelf: 'center', margin: '8px' }}>
                    <img style={{ height: '16px', width: '25px' }} src={Dollar}></img>
                  </div>
                  <div>
                    <p className='LogoTitle'>{`${totalPrice.toFixed(2)}`}</p>
                  </div>
                </div>
                {
                  !isFreeBooking && (
                    <div className="NonEditableView">
                      <div style={{ alignSelf: 'center', margin: '8px' }}>
                        {
                          cardName == 'visa' && (
                            <img style={{ height: '25', width: '25px' }} src={VisaSelected}></img>)
                        }
                        {
                          cardName == 'mastercard' && (
                            <img style={{ height: '25', width: '25px' }} src={MasterSelected}></img>)
                        }
                        {
                          cardName == 'amex' && (
                            <img style={{ height: '25', width: '25px' }} src={AmericanSelected}></img>)
                        }
                        {
                          cardName != 'visa' || cardName == 'mastercard' || cardName == 'amex' && (
                            <img style={{ height: '25', width: '25px' }} src={DefaultSelection}></img>)
                        }

                      </div>
                      <div className='CardStuff'>
                        <div>
                          <text className='LogoTitle'>{`Card Ending *${last4}`}</text>
                        </div>
                        <div>
                          <text className='LogoTitle' >{`Valid until ${expMonth}/${expYear}`}</text>
                        </div>
                      </div>
                    </div>
                  )
                }

              </div>
              <div className="BottomButtonContainer">
                <Button
                  style={isPayParkDisabled ? { marginTop: '50px', width: '100%', height: '50px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', color: '#FFF' } : { marginTop: '50px', width: '100%', height: '50px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }}
                  variant="contained"
                  disabled={isPayParkDisabled}
                  onClick={
                    handleConfirmPay
                  }
                >{`Pay & Park`}</Button>
              </div>
              <p className="BottomContainerText">By completing this booking I understand that I am agreeing to the Parking Spot Rental Agreement and Terms of Service</p>

            </div>
          </div>

        ) || (
          <div>
            <IconButton  style={{ backgroundColor: 'transparent' }} onClick={
              () => {
                navigate(-1)
              }}
            >
              <img className="Back-Image" src={Back}></img> <small className="BackButtonText"
              >Back</small>
            </IconButton>
            <p className="SearchTitle"> Complete Parking Purchase </p>
            <div className="SearchPropertyInputView-NonEdit">
              <IconButton>
                <img className="Arrow-Image" src={LocArrowsvg}></img>
              </IconButton>
              <input className="SearchInput" type="text" name="Address" placeholder="Enter Your Location" value={state.user.VehicleDetails.propertyName} readOnly />

            </div>
            <div style={{ width: '100%' }} className="Vehicle-InputView">
              <div className="User-Datail">

              </div>
              <form id="payment-form" onSubmit={handleSubmit}>
                <div className="CardTextFiledParentView">
                  <TextField autoComplete="off" fullWidth className="MatrialTextFieldFull" id="outlined-basic" label="Cardholder Name" variant="outlined" color="primary"
                  />
                  {/* card screen
                Here to implement apple pay */}
                  <div style={{ marginTop: '20px' }}>
                    {
                      paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />
                    }
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                  </div>
                </div>
                <div className="BottomButtonContainer">
                  {/* <Button id="submit" style={{width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'sans-serif', fontWeight: '700', borderRadius: '12px'}} variant="contained"> Next</Button> */}
                  <button id="submit" disabled={isNextDisabled}
                    style={isNextDisabled ? { width: '100%', height: '50px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', color: 'white', borderBlockColor: 'transparent', borderWidth: '0px', cursor: 'pointer' } : { width: '100%', height: '50px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', color: 'white', borderBlockColor: 'transparent', borderWidth: '0px', cursor: 'pointer' }}
                    variant="contained"> Next</button>
                </div>
              </form>
            </div>
          </div>
        )
      }
    </div>
  );
}