import React, { useEffect, useState, useRef } from "react";
import './PopUpView.css'
import { Button, TextField } from "@mui/material";
import CloseIcon from '../../Images/close-no-border.svg'
import TickImg from '../../Images/tick.svg'
import SendReceipt from "../../Screens/SendReceipt/SendReceipt";
import { isChrome, isMobile } from 'react-device-detect'

function PopUpView(_props: any) {
    const ref = useRef<HTMLInputElement>(null);
    const [friendlySpotId, setFriendlySpotId] = useState(_props.friendlySpotId);
    const [friendlyReservationId, setFriendlyReservationId] = useState(_props.friendlyReservationId);
    const [reservationGuid, setReservationGuid] = useState(_props.reservationGuid);
    const [height, setHeight] = useState(isChrome && isMobile ? 700: 630);
    

    useEffect(() => {
        // if (ref.current != null) {
        //     setHeight(ref.current.clientHeight)
        // }
        setFriendlySpotId(_props.friendlySpotId);
        setFriendlyReservationId(_props.friendlyReservationId);
        setReservationGuid(_props.reservationGuid);

    })

    return (
        <div style = {{}} className="popUpBasicContrainer">
            <div style={{ 'minHeight': `calc(100vh - ${height}px)` }} className="popUp-min-hight"></div>
            <div style={{}} ref={ref} className="pop-up">
                <div style={{ marginTop: '20px' }} className="RightView">
                    <div >
                        <img src={CloseIcon} className="close-icon"
                            onClick={() => {
                                _props.handelClose()
                            }}
                        />
                    </div>
                </div>

                <div style={{ 'overflowY': 'scroll', 'zIndex': 3 }} className="CenterView">
                    <SendReceipt
                        handelClose = {()=>{
                            _props.handelClose()
                        }}
                        reservationGuid={reservationGuid}
                        friendlySpotId={friendlySpotId}
                    ></SendReceipt>
                </div>
                {/* <div style={{'height':'80px'}}></div> */}
            </div>
        </div>
    )
}
export default PopUpView