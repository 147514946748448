import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import './AlertView.css'
import { IconButton } from "@mui/material";
const AlertView = (_props: any) => {

    // console.log("_props",_props.buttonType);
    const [buttonType, setButtonType] = useState("single");
    const [Title, setTitle] = useState("");
    const [SubTitle, setSubTitle] = useState("");
    const [BottomTitle, setBottomTitle] = useState("")


    useEffect(() => {
        setButtonType(_props.buttonType ? _props.buttonType : "single");
        setTitle(_props.title);
        setSubTitle(_props.subTitle);
        setBottomTitle(_props.BottomTitle ? _props.BottomTitle : "");
    })



    return (
        <div className="Alert_Screen">
            <div className="popup" style={{height: Title == ''?'180px': '250px'}}>
                <h1 className="Title">
                    {Title}
                </h1>
                {
                    Title == '' && (
                        <h1 className="SubTitle" style={{marginTop: '-15px',fontSize: '20px',fontWeight: 700}}>
                    {SubTitle}
                </h1>
                    )||(<text className="SubTitle">
                    {SubTitle}
                </text>)
                    
                }


                {
                    buttonType == 'dual' && (
                        <div className="DualButton">
                            <div style={{ width: '45%' }}>
                                <Button style={{ width: '100%', height: '58px', backgroundColor: '#FFFFFF', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="outlined"
                                    onClick={() => { _props.handleNo() }}>
                                    No
                                </Button>
                            </div>
                            <div style={{ width: '45%' }}>
                                <Button style={{ width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                                    onClick={() => { _props.handleYes() }}>
                                    Yes
                                </Button>
                            </div>
                        </div>
                    ) || (
                        <div className="SingleButton">
                            <Button style={{ width: '100%', height: '58px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: '20px' }} variant="contained"
                                onClick={() => {
                                    _props.handleOkay()
                                }}>
                                {"Okay"}
                            </Button>
                        </div>
                    )
                }
                <div style={{ marginBottom: '20px' }}>
                    {BottomTitle != "" && (
                        <IconButton onClick={
                            () => {
                                window.open("https://help.parkwithgryd.com/article/211-why-cant-i-make-a-booking")
                            }}

                        >
                            <text className="BottomTitle">
                                {BottomTitle}
                            </text>
                        </IconButton>

                    )
                    }
                </div>

            </div>
        </div>
    )

}
export default AlertView