
export default class Referesh {
    
    static tiggerUseEffectTimes: number;
    tiggerUseEffectTimes = 0;
    
    public static async handle(callback:any) {
            if (window.performance) {
                console.log("window.performance")
                if (performance.navigation.type == 1) {
                   callback();
                  
                } else {
                    
                }
            }
    }  
    

}
