import React, { useEffect, useState } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
import './Popup.css'
import CloseIcon from '../../Images/close-no-border.svg'
import API from "../../Services/API";
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
const burningGlassPropertyGuids = ["E35363AC-FC71-4EEC-A104-E04EABDE91B1", "66764983-E58B-4671-9264-9C66676D3E1C", "D25D0B4F-1FDF-41DA-9FC0-A101C5E4CD6D"];

const Popup = (_props: any) => {
    const [unitNumber, setUnitNumber] = useState("");
    const [passCode, setPassCode] = useState("");
    const [Title, setTitle] = useState("");
    const [SubTitle, setSubTitle] = useState("");
    const [PropertyGuid, setPropertyGuid] = useState("");
    const [unitNumbers, setUnitNumbers] = useState([])
    const [spinner, SetSpinner] = useState(false);

    const api = new API();

    useEffect(() => {
        setTitle(_props.title);
        setSubTitle(_props.subTitle);
        setPropertyGuid(_props.propertyGuid);

    })
    useEffect(() => {
        if (burningGlassPropertyGuids.indexOf(PropertyGuid.toUpperCase()) != -1) {
            SetSpinner(true);
            api.get_active_units(PropertyGuid,
                {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            ).then(async (result: any) => {
                SetSpinner(false);
                var unitnumbers: any = result.map((item: IGet_active_units) => item.unitNumber)
                setUnitNumbers(unitnumbers)
            }).catch(() => {
                console.log("error");
            }
            )
        }

    }, [PropertyGuid])
    const getHighlightedText = (text: string, highlight: string) => {
        // Split on highlight term and include term into parts, ignore case
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> {parts.map((part, i) =>
            <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}>
                {part}
            </span>)
        } </span>;
    }
    return (
        <div className="Popup_Screen">
            {
                    spinner && (
                        <SpinnerView />
                    )

                }
            <div className="Popup_View">                
                <div style={{ marginTop: '10px', 'width': '100%' }} className="RightView">
                    <img src={CloseIcon} className="close-icon"
                        onClick={() => {
                            _props.handelClose()
                        }}
                    />
                </div>
                <h1 className="Popup_Title">
                    {Title}
                </h1>
                {/* UnitNumber_BuildingNumber */}
                <text className="Popup_SubTitle">
                    {["Please enter the ", <text style={{ color: "black", fontWeight: 'bold' }}>{(burningGlassPropertyGuids.indexOf(PropertyGuid.toUpperCase()) != -1) ? ('UnitNumber_BuildingNumber'):('unit number')}</text>, " and", <text style={{ color: "black", fontWeight: 'bold' }}> <br /> {(burningGlassPropertyGuids.indexOf(PropertyGuid.toUpperCase()) != -1) ? ('Passcode'):('passcode')}</text>, " of the tenant you are visiting."]}
                </text>
                <div style={{ width: '90%' }}>
                    <div style={{ width: '100%', margin: 0 }}>
                        {
                            (burningGlassPropertyGuids.indexOf(PropertyGuid.toUpperCase()) != -1) && (

                                <Autocomplete
                                    id="combo-box-demo"
                                    options={unitNumbers}
                                    style={{ width: '100%' }}
                                    disableClearable
                                    onChange={(e, value) => {
                                        setUnitNumber(value)
                                    }}
                                    ListboxProps={{ style: { maxHeight: 150 } }}

                                    getOptionLabel={option => option}
                                    renderInput={(params) => (
                                        <div>
                                            <TextField
                                                {...params}
                                                label="Unit Number"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,

                                                }}
                                                fullWidth
                                            />
                                        </div>
                                    )}
                                ></Autocomplete>
                            ) || <TextField fullWidth className="MatrialTextFieldFull"
                                id="outlined-basic" variant="outlined"
                                color="primary" autoComplete="off" value={unitNumber}
                                label={"Unit Number"}
                                inputProps={{
                                    style: { fontFamily: "Manrope", fontWeight: 500 },
                                    maxLength: 20

                                }

                                }

                                onChange={(e) => {
                                    //for android max length not working
                                    if (e.target.value.length < 21) {
                                        setUnitNumber(e.target.value)
                                    }

                                }}
                                onFocus={(e) => {


                                }}
                                onBlur={(e) => {


                                }}
                            />
                        }

                    </div>
                    {
                        (PropertyGuid != undefined) && (
                            (
                                (burningGlassPropertyGuids.indexOf(PropertyGuid.toUpperCase()) != -1) && (
                                    <small style={{ 'fontFamily': "Manrope", 'color': 'gray', 'marginLeft': '18px' }}>{`Ex: UnitNumber_BuildingNumber `} </small>
                                )
                            )

                        )

                    }
                    <div style={{ width: '100%', marginTop: 16 }}>
                        <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" variant="outlined" color="primary" autoComplete="off" value={passCode}
                            inputProps={{ maxLength: 5 }}
                            label={"Passcode"}
                            type={'password'}
                            onChange={(e) => {
                                setPassCode(e.target.value)
                            }}
                            onFocus={(e) => {


                            }}
                            onBlur={(e) => {


                            }}

                        />
                    </div>
                </div>

                <div className="SingleButton" style={{ marginTop: 21 }}>
                    <Button style={{ width: '100%', height: '58px', backgroundColor: (unitNumber.length > 0 && passCode.length > 0) ? '#00AF85' : '#8A959E', color: 'white', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px', marginBottom: 24 }} variant="contained"
                        disabled={(unitNumber.length > 0 && passCode.length > 0) ? false : true}
                        onClick={() => {
                            _props.handleContinue(unitNumber, passCode)
                        }}>
                        {"Continue"}
                    </Button>
                </div>
            </div>
        </div>

    )
}
export default Popup;
interface IGet_active_units {
    "unitGuid": string,
    "unitNumber": string,
    "propertyGuid": string,
    "passCount": number,
    "passCode": string | null,
    "totalPassBooked": number
}
