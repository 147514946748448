import { Box, Button, InputAdornment, MenuItem, Select, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import SearchComponent from "../../Components/Search/Search";
import { useNavigate } from 'react-router-dom';
import TimeSvg from '../../Images/Time.svg';
import './Duration.css'
import API from "../../Services/API";
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index"
import { SpinnerCircular } from 'spinners-react';
import CircularProgress from '@mui/material/CircularProgress';
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import AlertView from "../../Components/AlertView/AlertView";
import AlertModel from "../../Model/AlertModel";
import Refresh from "../../Common/Refresh";
import ContactUsView from "../../Components/ContactUsView/ContactUs";
import Vehicle from "../Vehicle/Vehicle";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { decodeJWT } from "../../Common/utils";

let durationFlag: string = "";

const Duration = () => {
    let DurationInMin: number
    var dataAlert: AlertModel
    const Location: any = useLocation();
    dataAlert = {}
    var tiggerUseEffectTimes = 0;
    const [waringBorder, SetWaringBorder] = useState(false)
    const [alertData, setAlertData] = useState(dataAlert)
    const state = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const { addDuration } = bindActionCreators(actionCreators, dispatch);
    const { addHourly } = bindActionCreators(actionCreators, dispatch);
    const navigate = useNavigate();
    const [spinner, SetSpinner] = useState(false);
    const [enableNext, SetEnableNext] = useState(false);
    const DurationDetails = state.user.DurationDetails;
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalPriceApi, setTotalPriceApi] = useState(0);
    const [durationInTime, SetDurationInTime] = useState("00:00")
    const [duration, setDuration] = useState(DurationDetails.Duration);
    const [priceWithTax, setPriceWithTax] = useState("00:00")
    const [durationtype, SetDurationtype] = useState(DurationDetails.DurationType == "" ? "Minutes" : DurationDetails.DurationType);
    const [footerPostion, setFooterPosition] = useState("fixed")
    const [DurationInMins, setDurationInMins] = useState(15);
    const [showHourLimitAlert, setShowHourLimitAlert] = useState(false)
    const [via, setVia] = useState(Location.state ? Location.state.Via : "");
    const [errorTime, setErrorTime] = useState("");
    const [showNoVistorPassAlert, setShowNoVistorPassAlert] = useState(false);
    const [dntCallApi, setDntCallApi] = useState(false);
    const [showContactUS, SetShowContactUS] = useState(false);
    const handleSearchTF = (value: any) => {

    }
    // console.log("redux-state", state);

    const api = new API();
    const appInsights = useAppInsightsContext();
    const validatedData = () => {
        console.log("redux in duration",state);
        if (state.user.VehicleDetails.make == "" || state.user.VehicleDetails.model == "" || state.user.VehicleDetails.color == "" || state.user.VehicleDetails.licensePlate == "") {
            return true;
        } else {
            return false;
        }
    }
    const dropdownMenuProps = {
        menuStyle: {
            border: "1px solid black",
            borderRadius: "5%",
            backgroundColor: 'lightgrey',
        },
    }

    useEffect(() => {
        if (tiggerUseEffectTimes == 0) {
            tiggerUseEffectTimes = 1
            Refresh.handle(
                () => {
                    setTimeout(() => {
                        if (validatedData()) {
                            console.log("");
                            navigate('/')
                        }
                    }, 500);
                    
                }
            )
        }
    }, [])
    useEffect(() => {

        CheckandTriggerUnholdSpot();

    }, [])

    useEffect(() => {
        addToAzureAppInsight({ User_Guid: "", Action: "current-screen-duration", Page: "Duration" })
    }, [])

    useEffect(() => {
        durationFlag = Math.random().toString();
        if (duration.length == 0 || duration == 0) {
            setTotalPrice(0)
            setDuration("")
            SetEnableNext(false)
        } else {
            SetEnableNext(true)
        }

        getTotalAmt(durationFlag);
    }, [duration, durationtype])

    useEffect(() => {
    }, [totalPrice, enableNext])

    // Validation for minutes and hours
    useEffect(() => {
        if (durationtype == "Minutes") {
            if (duration >= 60) {
                setDuration(0)
            }

        } else if (durationtype == "Hours") {
            if (duration >= 48) {
                setDuration(0)
            }
        }
    }, [durationtype])


    const addToAzureAppInsight = (Payload: {
        "User_Guid": string,
        "Action": string,
        "Page": string,
        "PayLoad"?: any
    }, Type?: string) => {
        try {
            var Payload = Payload;
            var StoreType = Type ? Type : 'Basic'
            if (Payload["User_Guid"] == "") {
                var userguid = decodeJWT(String(localStorage.getItem('Stored-AccessToken')), 'userguid');
                Payload["User_Guid"] = userguid;
            }
            if (StoreType == 'Basic') {
                appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
            }
            if (process.env.REACT_APP_APP_INSIGHT_TYPE == 'Detail' && StoreType == 'Detail') {
                appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    var time = ""
    const CheckandTriggerUnholdSpot = async () => {
        const tempBlockGuid = sessionStorage.getItem('Temp-blockGuid')
        // console.log("tempBlockGuid", tempBlockGuid);
        if (tempBlockGuid && tempBlockGuid != 'null') {
            const unhold_spot = await api.unhold_spot(tempBlockGuid ? tempBlockGuid : "", {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
            })
            if (unhold_spot == 200) {
                addToAzureAppInsight({ User_Guid: "", Action: "unhold-spot", Page: "Duration" }, 'Detail');
                sessionStorage.setItem('Temp-blockGuid', 'null');
            }
        }
    }
    const customAlert = () => {
        var Title = (alertData.alertData?.title) ? alertData.alertData?.title : ""
        var SubTitle = (alertData.alertData?.subTitle) ? alertData.alertData?.subTitle : ""
        var ButtonType = (alertData.alertData?.typeButton) ? alertData.alertData?.typeButton : ""
        var BottomTitle = (alertData.alertData?.bottonButton) ? alertData.alertData?.bottonButton : ""

        return (
            alertData.showAlert && (
                <AlertView title={Title} subTitle={SubTitle} buttonType={ButtonType} BottomTitle={BottomTitle}
                    handleOkay={(value: any) => {
                        handelOkayButton()
                    }} />
            )
        )
    }
    const Contactus = () => {
        return (
            <ContactUsView
                handelClose={
                    (value: any) => {
                        SetShowContactUS(false);
                    }
                }
            ></ContactUsView>
        )
    }
    const handelOkayButton = () => {
        setAlertData({ showAlert: false })
        navigate('/')
    }
    const noVistorPassAlert = () => {
        return (

            <AlertView title={'Parking Unavailable'} subTitle={<div>
                <small style={{ 'fontFamily': 'Manrope', 'fontSize': '15px', 'fontWeight': 400, 'color': '#323232' }}>
                    This tenant used the maximum number of <br />
                    visitor parking passes allowed for this <br />
                    month. For help please <small style={{ 'fontFamily': 'Manrope', 'fontSize': '15px', 'fontWeight': 400, 'textDecorationLine': 'underline', 'color': '#00AF85' }}
                        onClick={
                            () => {
                                addToAzureAppInsight({ User_Guid: "", Action: "contact-us", Page: "Duration" })
                                SetShowContactUS(true);
                                setShowNoVistorPassAlert(false);
                            }
                        }
                    >
                        contact us
                    </small>
                </small>
            </div>} buttonType={'single'} BottomTitle={''}
                handleOkay={(value: any) => {
                    setShowNoVistorPassAlert(false)
                    SetShowContactUS(true);
                    navigate("/");
                }}

            />
        )
    }
    const getTotalAmt = async (flag: string) => {
        setTotalPrice(0)
        if (durationtype == "Minutes") {
            DurationInMin = parseInt(duration)
        } else {
            DurationInMin = Math.ceil(Number(duration) * 60)
        }
        setDurationInMins(DurationInMin);
        SetDurationInTime(time);
        SetSpinner(true);
        SetEnableNext(false);
        if (via == "Extend") {
            var postData: any = {
                "DurationInMinutes": DurationInMin ? DurationInMin : 15,
                "PropertyGuid": localStorage.getItem('Stored-PropertyGuid'),
                "SpotGuid": localStorage.getItem('Stored-spotGuid'),
                "ReservationGuid": localStorage.getItem('Stored-reservationGuid'),
                "IsGuestAndVisitor": true,
            }
            if (localStorage.getItem('UnitGuid')) {
                postData["UnitGuid"] = localStorage.getItem('UnitGuid');
            }
            api.spot_availability(
                postData
                , {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
                }).then((result) => {
                    if (flag != durationFlag) {
                        // console.log(`Ignored ${DurationInMin} minutes API response.`)
                        return;
                    }
                    var data = JSON.parse(result)
                    //  console.log("spot_availability", data.response,"status",data.status);
                    if (data.status == 200) {
                        setShowHourLimitAlert(false);
                        SetWaringBorder(false)
                        if (typeof data.response === 'string') {
                            setAlertData({
                                showAlert: true, alertData: {
                                    title: "GrydPark",
                                    subTitle: data.response,
                                    typeButton: "single"

                                }
                            })
                        }
                        else if (!data.response.isBookable || !data.response.isExtension) {
                            addToAzureAppInsight({ User_Guid: "", Action: "no-spot-available-extend-alert", Page: "Duration" }, 'Detail')
                            setAlertData({
                                showAlert: true, alertData: {
                                    title: "This Lot is Full",
                                    subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                    bottonButton: `I see empty spots, why can’t I book? `
                                }
                            })
                        }
                        SetSpinner(false);
                        SetEnableNext(true);
                        if (data.response.bestRate != null) {
                            let totalPriceWithServiceFeeTax = data.response.bestRate.totalPriceWithServiceFeeTax;
                            let totalPrice = data.response.bestRate.totalPrice;
                            // console.log("duration is ", duration, "total", totalPriceWithServiceFeeTax);
                            setTotalPrice(totalPriceWithServiceFeeTax);
                            setTotalPriceApi(totalPrice);
                            if (!duration || duration == 0) {
                                setTotalPrice(0);
                                setTotalPriceApi(0);
                                SetEnableNext(false);
                            }

                        } else if (!data.response.bestRate.totalPriceWithServiceFeeTax) {
                            addToAzureAppInsight({ User_Guid: "", Action: 'no-price-available-extend-alert', Page: "Duration" }, 'Detail')
                            setTotalPrice(0);
                            setTotalPriceApi(0);
                            setAlertData({
                                showAlert: true, alertData: {
                                    title: "GrydPark",
                                    subTitle: "Sorry, no price is availabe",
                                    typeButton: "single"
                                }
                            })
                        }
                        else {
                            addToAzureAppInsight({ User_Guid: "", Action: "no-spots-available-at-this-moment-extend-alert", Page: "Duration" }, 'Detail')
                            SetEnableNext(false)
                            setTotalPrice(0);
                            setTotalPriceApi(0);
                            setAlertData({
                                showAlert: true, alertData: {
                                    title: "GrydPark",
                                    subTitle: "Sorry, there are no spots available at this moment, please try again later",
                                    typeButton: "single"
                                }
                            })
                        }
                    }
                    else if (data.status == 409) {
                        addToAzureAppInsight({ User_Guid: "", Action: "hour-limit-alert-extend", Page: "Duration" }, 'Detail')
                        setShowHourLimitAlert(true)
                        SetWaringBorder(true)
                        setErrorTime(data.response ? data.response : "");
                        // console.log("409", data.response)
                        setTotalPrice(0)
                        SetEnableNext(false)


                    }
                    else if (data.status == 404) {
                        setShowHourLimitAlert(false)
                        SetWaringBorder(false)
                        setErrorTime("");
                        // console.log("404", data.response)
                        addToAzureAppInsight({ User_Guid: "", Action: "no-vistor-pass-alert-extend", Page: "Duration" }, 'Detail')
                        setShowNoVistorPassAlert(true);
                        setTotalPrice(0)
                        SetEnableNext(false)

                    }
                    else if (data.status == 400) {
                        setShowHourLimitAlert(false)
                        SetWaringBorder(false)
                        setTotalPrice(0)
                        SetEnableNext(false)
                        // console.log("error is ", data.response);
                        addToAzureAppInsight({ User_Guid: "", Action: "no-spots-available-at-this-moment-extend", Page: "Duration" }, 'Detail')
                        setAlertData({
                            showAlert: true, alertData: {
                                title: "GrydPark",
                                subTitle: data.response ? JSON.parse(data.response) : "Sorry, there are no spots available at this moment, please try again later",
                                typeButton: "single"
                            }
                        })
                    }

                })
        } else {
            var postData: any = {
                "SearchLatitiude": state.user.VehicleDetails.curLatitude,
                "SearchLongitude": state.user.VehicleDetails.curLongitude,
                "PropertyGuid": state.user.VehicleDetails.propertyGuid,
                "DurationInMinutes": DurationInMin ? DurationInMin : 15,
            }
            if (localStorage.getItem('UnitGuid')) {
                postData["UnitGuid"] = localStorage.getItem('UnitGuid')
            }
            const res = await api.property_availability(postData)

            if (flag != durationFlag) {
                // console.log(`Ignored ${DurationInMin} minutes API response.`)
                return;
            }

            // console.log("duration.value", state.user.VehicleDetails.propertyGuid);
            var data = JSON.parse(res)
            SetSpinner(false);
            if (data.status == 200) {
                setShowHourLimitAlert(false)
                SetWaringBorder(false)
                setErrorTime("");
                if (data.response != null) {

                    SetEnableNext(true);
                    if (!data.response.hasAvailableSpots) {
                        addToAzureAppInsight({ User_Guid: "", Action: "no-spot-available-alert", Page: "Duration" }, 'Detail')
                        setAlertData({
                            showAlert: true, alertData: {
                                title: "This Lot is Full",
                                subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                bottonButton: `I see empty spots, why can’t I book? `
                            }
                        })
                    }
                    if (data.response.booking != null) {
                        if (data.response.booking.bestRate) {
                            if ((duration != 0)) {
                                addToAzureAppInsight({ User_Guid: "", Action: `user-check-total-price-before-confirm`, Page: "Duration", PayLoad: { 'TotalPrice': data.response.booking.bestRate.totalPriceWithServiceFeeTax } }, 'Detail')
                                setTotalPrice(data.response.booking.bestRate.totalPriceWithServiceFeeTax);
                                setTotalPriceApi(data.response.booking.bestRate.totalPrice);
                            }
                        } else {
                            addToAzureAppInsight({ User_Guid: "", Action: `price-is-not-available-alert`, Page: "Duration" }, 'Detail')

                            SetEnableNext(false)
                            setTotalPrice(0);
                            setTotalPriceApi(0);
                            addToAzureAppInsight({ User_Guid: "", Action: "price-is-not-available-please-try-again-later", Page: "Duration" }, 'Detail')
                            setAlertData({
                                showAlert: true, alertData: {
                                    title: "GrydPark",
                                    subTitle: "Sorry, Price is not available, please try again later",
                                    typeButton: "single"
                                }
                            })
                        }
                    } else {
                        SetEnableNext(false)
                        setTotalPrice(0);
                        setTotalPriceApi(0);
                        SetWaringBorder(false);
                        setShowHourLimitAlert(false);
                    }
                    if (duration == 0) {
                        SetEnableNext(false)
                        setTotalPrice(0);
                        setTotalPriceApi(0);
                        SetWaringBorder(false);
                        setShowHourLimitAlert(false);
                    }
                }
            } else if (data.status == 409) {
                setShowHourLimitAlert(true)
                SetWaringBorder(true)
                setErrorTime(data.response ? data.response : "");
                // console.log("409", data.response)
                setTotalPrice(0)
                SetEnableNext(false);
                addToAzureAppInsight({ User_Guid: "", Action: "hour-limit-alert", Page: "Duration" }, 'Detail')


            }
            else if (data.status == 404) {
                setShowHourLimitAlert(false)
                SetWaringBorder(false)
                setErrorTime("");
                // console.log("404", data.response)
                setShowNoVistorPassAlert(true);
                setTotalPrice(0)
                SetEnableNext(false)
                addToAzureAppInsight({ User_Guid: "", Action: "no-vistor-pass-alert", Page: "Duration" }, 'Detail')

            }
        }
    }




    return (
        <div className="VehicleBaseContiner">
            {
                spinner && (
                    <SpinnerView />
                )

            }
            {
                customAlert()
            }
            {
                showNoVistorPassAlert && (
                    noVistorPassAlert()
                )
            }
            {
                showContactUS &&
                (
                    Contactus()
                )
            }
            <SearchComponent ScreenName={"Duration"} handleParentFun={(value: any) => {
                handleSearchTF(value);
            }}
            ></SearchComponent>



            <div className="Vehicle-InputView">
                <div>
                    <h3 className="DurationTitle">Enter Duration</h3>
                </div>
                <div className="DurationRow">
                    <div className="DurationParentView">
                        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                            <TextField
                                className="MatrialTextFieldFull"
                                id="input-with-icon-textfield"
                                autoComplete="off"
                                color={waringBorder ? 'warning' : 'primary'}
                                value={duration}
                                onBlur={() => { }
                                }
                                onChange={async (e) => {

                                    // setShowHourLimitAlert(false);
                                    // SetEnableNext(false);
                                    // SetWaringBorder(false);

                                    if (durationtype == "Minutes") {
                                        if (Number(e.target.value) <= 60) {
                                            setDuration(e.target.value)
                                        }
                                    } else {
                                        if (Number(e.target.value) <= 48) {
                                            setDuration(e.target.value)
                                        }
                                    }


                                }}
                                label=""
                                inputProps={{
                                    inputMode: 'decimal',
                                    maxLength: 4
                                }}
                                InputProps={{
                                    inputMode: 'decimal',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={TimeSvg}></img>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </div>
                    <div className="DurationParentTypeView">
                        <Select
                            fullWidth
                            defaultValue="Minutes"
                            className="InputLabel"
                            value={durationtype}
                            onChange={(e) => {
                                SetEnableNext(false);
                                SetDurationtype(e.target.value)
                                // setDuration("")
                            }}
                            MenuProps={{
                                sx: {
                                    "&& .Mui-selected": {
                                        borderBottom: "1px solid #F8F8F8",
                                        backgroundColor: 'white'
                                    },
                                }
                            }}
                        >
                            <MenuItem defaultValue={"Minutes"} value={"Minutes"}>Minutes</MenuItem>
                            <MenuItem value={"Hours"}>Hours</MenuItem>
                        </Select>

                    </div>
                </div>
                {
                    showHourLimitAlert && (
                        <div style={{ height: '52px', width: '100%', backgroundColor: '#F8F8F8', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: '10px', borderRadius: 10 }}>
                            <small style={{ 'margin': '20px', 'fontFamily': 'Manrope', fontWeight: '400', fontSize: '15px', color: '#DC2626' }}>{errorTime}</small>
                        </div>
                    )
                }

            </div>
            <div style={{ position: 'relative' }} className="Next-View">
                <div className="DurationTotalRow">
                    <h3 className="DurationTotal">Total</h3>
                    <h3 className="DurationAmount">${totalPrice == 0 ? "0.00" : totalPrice.toFixed(2)}</h3>
                </div>
                <div className="DurationNextView">
                    <Button style={enableNext ? { width: '100%', height: '50px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' } : { width: '100%', height: '50px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="contained" onClick={
                        async () => {

                            if (!duration || !enableNext) {

                            } else {
                                addToAzureAppInsight({ User_Guid: "", Action: "next-button", Page: "Duration" });
                                if (via == "Extend") {
                                    addToAzureAppInsight({ User_Guid: "", Action: "next-button-via-extend", Page: "Duration" }, 'Detail');
                                }
                                var AccessToken = "";
                                var VehicleGuid = "";
                                SetSpinner(true);
                                const getAccessTokenResponse = await api.get_vehicle_accesstoken({
                                    "VehicleMake": state.user.VehicleDetails.make,
                                    "VehicleModel": state.user.VehicleDetails.model,
                                    "VehicleColour": state.user.VehicleDetails.color,
                                    "VehicleLicense": state.user.VehicleDetails.licensePlate,
                                    "PhoneNumber": state.user.VehicleDetails.phoneNumber,
                                    "AccessToken": localStorage.getItem('Stored-AccessToken') ? localStorage.getItem('Stored-AccessToken') : "",
                                    "vehicleGuid": ""
                                })
                                if (getAccessTokenResponse != null) {
                                    addToAzureAppInsight({ User_Guid: "", Action: "get-Access-token", Page: "Duration" }, 'Detail');
                                    AccessToken = getAccessTokenResponse.accessToken;
                                    VehicleGuid = getAccessTokenResponse.vehicleGuid;
                                    localStorage.setItem('Stored-AccessToken', AccessToken)
                                    localStorage.setItem('Stored-PropertyGuid', state.user.VehicleDetails.propertyGuid);
                                }

                                let holdspotPostData: any = {
                                    "VehicleGuid": VehicleGuid,
                                    "PropertyGuid": state.user.VehicleDetails.propertyGuid,
                                    "DurationInMinutes": DurationInMins,
                                    "BookingTotalPrice": totalPriceApi,
                                }
                                if (via == "Extend") {
                                    holdspotPostData["reservationGuid"] = localStorage.getItem('Stored-reservationGuid')
                                }
                                if (localStorage.getItem('UnitGuid')) {
                                    holdspotPostData["UnitGuid"] = localStorage.getItem('UnitGuid')
                                }
                                const res = await api.hold_spot(holdspotPostData, {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + AccessToken
                                })
                                //console.log("holdSpot", holdSpot.booking.bestRate.hourlyDetails);
                                var data = JSON.parse(res)
                                if (data.status == 200) {
                                    if (data.response.blockGuid) {
                                        addToAzureAppInsight({
                                            User_Guid: "", Action: `trigger-hold-spot`, Page: "Duration", PayLoad: {
                                                "BlockGuid": data.response.blockGuid
                                            }
                                        }, 'Detail');
                                        if (via == 'Extend') {
                                            addToAzureAppInsight({
                                                User_Guid: "", Action: `trigger-hold-spot-via-extend `, Page: "Duration", PayLoad: {
                                                    "BlockGuid": data.response.blockGuid
                                                }
                                            }, 'Detail');
                                        }
                                    }
                                    setShowHourLimitAlert(false)
                                    SetWaringBorder(false)
                                    setErrorTime("");
                                    const isSpotsAvailable = data.response.hasAvailableSpots ? data.response.hasAvailableSpots : false
                                    if (isSpotsAvailable) {
                                        var HourlyDetails = data.response.booking.bestRate.hourlyDetails;
                                        let endTime = data.response.endTime ? data.response.endTime : "";
                                        // console.log("endTime", endTime);
                                        var HourlyDetailsObj;
                                        for (var i = 0; i < HourlyDetails.length; i++) {
                                            HourlyDetailsObj = HourlyDetails[i]

                                        }
                                        if (HourlyDetails.length != 0) {
                                            //console.log("HourlyDetails came here", HourlyDetails);
                                            addHourly(HourlyDetailsObj);
                                        }
                                        if (data.response.blockGuid != null && isSpotsAvailable) {
                                            // console.log("data.response.blockGuid ", data.response.blockGuid);
                                            sessionStorage.setItem('Temp-blockGuid', data.response.blockGuid);
                                          //  console.log("sessionStorage", sessionStorage.getItem('Temp-blockGuid'))
                                            if (data.response != null) {
                                                if (totalPrice == 0) {
                                                    // console.log("localStorage.getItem('Stored-PaymentId')",localStorage.getItem('Stored-PaymentId'))
                                                    // if (localStorage.getItem('Stored-PaymentId')) {
                                                    const PaymentIntent = await api.initiate_payment({
                                                        "TemporaryBlockGuid": data.response.blockGuid,
                                                        "PaymentCardId": localStorage.getItem('Stored-PaymentId') ? localStorage.getItem('Stored-PaymentId') : ""
                                                    },

                                                        {
                                                            'Accept': 'application/json',
                                                            'Content-Type': 'application/json',
                                                            'Authorization': 'Bearer ' + AccessToken
                                                        }
                                                    )
                                                    if (PaymentIntent != 'non200') {
                                                        addToAzureAppInsight({ User_Guid: "", Action: via == 'Extend' ? `initiate-zero-dollar-booking-extend` : `initiate-zero-dollar-booking`, Page: "Duration" }, 'Detail')
                                                        setTimeout(() => {
                                                            navigate('/cardscreen', {
                                                                state:
                                                                {
                                                                    id: 1,
                                                                    AccessToken: 'Bearer ' + AccessToken,
                                                                    blockGuid: data.response.blockGuid,
                                                                    totalPrice: totalPrice,
                                                                    totalPriceApi: totalPriceApi,
                                                                    freebooking: true,
                                                                    PaymentIntent: PaymentIntent ? PaymentIntent : "",
                                                                    bookingEndtime: endTime


                                                                }
                                                            })
                                                        }, 500);

                                                    } else {

                                                    }
                                                    // }
                                                } else {

                                                    navigate('/cardscreen', {
                                                        state:
                                                        {
                                                            id: 1,
                                                            AccessToken: 'Bearer ' + AccessToken,
                                                            blockGuid: data.response.blockGuid,
                                                            totalPrice: totalPrice,
                                                            totalPriceApi: totalPriceApi,
                                                            freebooking: false,
                                                            PaymentIntent: "",
                                                            bookingEndtime: endTime

                                                        }
                                                    })
                                                }


                                            }
                                        } else {
                                            // setAlertData({
                                            //     showAlert: true, alertData: {
                                            //         title: "Sorry",
                                            //         subTitle: "There are no spots available at this moment, please try again later",
                                            //         typeButton: "Single"

                                            //     }
                                            // })
                                            addToAzureAppInsight({ User_Guid: "", Action: `error-lot-is-full`, Page: "Duration" });
                                            setAlertData({
                                                showAlert: true, alertData: {
                                                    title: "This Lot is Full",
                                                    subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                                    bottonButton: `I see empty spots, why can’t I book? `
                                                }
                                            })
                                        }
                                    } else {
                                        addToAzureAppInsight({ User_Guid: "", Action: `error-lot-is-full`, Page: "Duration" });
                                        setAlertData({
                                            showAlert: true, alertData: {
                                                title: "This Lot is Full",
                                                subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                                bottonButton: `I see empty spots, why can’t I book? `
                                            }
                                        })
                                    }
                                    if (duration && durationtype) {
                                        var DurationDetails = {
                                            Duration: duration,
                                            DurationType: durationtype
                                        }
                                        addDuration(DurationDetails);
                                        // navigate('/cardscreen')
                                    } else {
                                        console.log("error");

                                    }

                                } else if (data.status == 409) {
                                    addToAzureAppInsight({ User_Guid: "", Action: `hour-limit-alert`, Page: "Duration" }, 'Detail');
                                    setShowHourLimitAlert(true)
                                    SetWaringBorder(true)
                                    setErrorTime(data.response ? data.response : "");
                                    //console.log("409", data.response)
                                    setTotalPrice(0)
                                    SetEnableNext(false);

                                }
                                else if (data.status == 404) {
                                    setShowHourLimitAlert(false)
                                    SetWaringBorder(false)
                                    setErrorTime("");
                                   // console.log("404", data.response)
                                    setShowNoVistorPassAlert(true);
                                    addToAzureAppInsight({ User_Guid: "", Action: `no-visitor-pass-alert`, Page: "Duration" }, 'Detail');
                                    if (data.response == "UNIT_LIMIT_REACHED") {
                                        //here to display contact us alert
                                        // setShowNoVistorPassAlert(true);
                                    }
                                    setTotalPrice(0)

                                }


                                SetSpinner(false);
                            }

                            addToAzureAppInsight({ User_Guid: "", Action: "next-button", Page: "Duration" })
                        }}>Next</Button>
                </div>
            </div>
        </div>
    )
}

export default Duration


