import React, { useState, useEffect } from "react";
import SearchComponent from "../../Components/Search/Search";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { Button, CircularProgress, TextField } from "@mui/material";
import API from "../../Services/API";
import './CardScreen.css'
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import Refresh from "../../Common/Refresh";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux"
import AlertView from "../../Components/AlertView/AlertView";
import AlertModel from "../../Model/AlertModel";
import PopUpView from "../../Components/Popup/PopUpView";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index";
import { useDispatch } from "react-redux";

const CardScreen = () => {
    const location: any = useLocation();
    const state = useSelector((state: any) => state);
    const promise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);
    const [cardHolderName, setCardHolderName] = useState("");
    const api = new API()
    const [spinner, SetSpinner] = useState(false);
    const [screenName, setScreenName] = useState("card");
    const [showAlertBG, setShowAlertBG] = useState(false);
    const [ReceiptPopup, setReceiptPopup] = useState(false);
    const [friendlySpotId, setFriendlySpotId] = useState("");
    const [friendlyReservationId, setFriendlyReservationId] = useState("");
    const [reservationGuid, setReservationGuid] = useState("");
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_KEY as string))
    var tiggerUseEffectTimes = 0;
    var dataAlert: AlertModel
    dataAlert = {}
    const [alertData, setAlertData] = useState(dataAlert)
    const dispatch = useDispatch();
    const { addVehicle } = bindActionCreators(actionCreators, dispatch);
    const { addDuration } = bindActionCreators(actionCreators, dispatch);


    const validatedData = () => {
        if (state.user.VehicleDetails.make == "" || state.user.VehicleDetails.model == "" || state.user.VehicleDetails.color == "" || state.user.VehicleDetails.licensePlate == "" || state.user.DurationDetails.Duration == "", state.user.DurationDetails.Duration == "") {
            return true;
        } else {
            return false;
        }
    }
    const customAlert = () => {
        var Title = (alertData.alertData?.title) ? alertData.alertData?.title : ""
        var SubTitle = (alertData.alertData?.subTitle) ? alertData.alertData?.subTitle : ""
        var ButtonType = (alertData.alertData?.typeButton) ? alertData.alertData?.typeButton : ""
        var BottomTitle = (alertData.alertData?.bottonButton) ? alertData.alertData?.bottonButton : ""

        return (
            alertData.showAlert && (
                <AlertView title={Title} subTitle={SubTitle} buttonType={ButtonType} BottomTitle={BottomTitle}
                    handleOkay={(value: any) => {
                        handelOkayButton()
                    }} />
            )
        )
    }
    const handelOkayButton = async () => {
        // const unhold_spot = await api.unhold_spot(location.state.blockGuid, {
        //     'Accept': 'application/json',
        //     'Content-Type': 'application/json',
        //     'Authorization': location.state.AccessToken
        // })
        // if (unhold_spot == 200) {
        //    // navigate(-1)
        // }
        setAlertData({ showAlert: false })
    }

    useEffect(() => {
        if (tiggerUseEffectTimes == 0) {
            tiggerUseEffectTimes = 1
            Refresh.handle(
                () => {
                    if (validatedData()) {
                        navigate('/')
                    }
                }
            )
        }
    }, [])

    useEffect(() => {

    }, [spinner])

    const handleSpinner = async (value: boolean) => {
        SetSpinner(value);
    }
    const handleTitle = async (value: boolean) => {
        setScreenName("ConfirmParking");
    }
    const handleAlert = async (value: string) => {
        if (value == "") {

        }
        else if (value == "show-alert-BG") {
            setShowAlertBG(true);
        }
        else if (value == "hide-alert-BG") {
            setShowAlertBG(false);
        }
        else if (value == "dnt-call-Alert") {
            setAlertData({ showAlert: false })
        }
        else {
            setAlertData({
                showAlert: true, alertData: {
                    title: "GrydPark",
                    subTitle: value,
                    typeButton: "single"

                }
            })
        }

    }
    return (
        <div className="VehicleBaseContiner">
            {
                spinner && (
                    <SpinnerView />
                )
            }
            {
                customAlert()
            }
            {showAlertBG && (
                <div className="Alert_Screen_only"></div>
            )
            }

            <div className="CardContainer">
                <Elements stripe={promise}  >
                    <CheckoutForm allow="payment" handleParentSpinner={(value: any) => {
                        handleSpinner(value);
                    }} handleParentTitle={(value: any) => {
                        handleTitle(value);
                    }}
                        handleParentAlert={(value: string) => {
                            handleAlert(value);
                        }}
                        handleReceiptPopUp={(value: any) => {
                            setReceiptPopup(true)
                            setFriendlyReservationId(value.friendlyReservationId);
                            setFriendlySpotId(value.friendlySpotId);
                            setReservationGuid(value.reservationGuid);
                        }}
                    />
                </Elements>
            </div>
            {
                ReceiptPopup && (
                    <PopUpView
                        friendlySpotId={friendlySpotId}
                        friendlyReservationId={friendlyReservationId}
                        reservationGuid={reservationGuid}
                        handelClose={
                            () => {
                                addVehicle({});
                                var DurationDetails = {
                                    Duration: 0,
                                    DurationType: "Minutes"
                                }
                                addDuration(DurationDetails);
                                navigate('/');
                            }
                        }
                    >

                    </PopUpView>
                )
            }
        </div>
    )

}
export default CardScreen