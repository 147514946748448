import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import './ContactUs.css'
import { IconButton } from "@mui/material";
import CloseIcon from '../../Images/close-no-border.svg'
import MailIcon from '../../Images/mail.png';
import PhoneIcon from '../../Images/phone.png';


const ContactUsView = (_props: any) => {
    useEffect(() => {

    })
    function redirect() {
        window.location.href = "mailto:mail@example.org";
    }
    return (
        <div className="Alert_Screen">
            <div className="popup-CU">

                <div className="Popup_View">
                    <div style={{ marginTop: '10px', 'width': '100%' }} className="RightView">
                        <img src={CloseIcon} className="close-icon-CU"
                            onClick={() => {
                                _props.handelClose()
                            }}
                        />
                    </div>
                    <h1 className="Popup-Title-CU">
                        {"Contact Us"}
                    </h1>
                        <div>
                            <h3 style={{ 'display': 'flex', 'flexDirection': 'row' }} className="subtitle-CU"  
                            onClick={
                                    ()=>{
                                        // console.log("clicked");
                                        window.location.href = "mailto:support@gryd.com";
                                    }
                                }
                                >
                                {/* 587 905 9205 */}
                                <img src={MailIcon} className="mail-icon"
                               
                                />
                                support@gryd.com
                            </h3>
                        </div>
                        <div >
                            <h3 style={{ 'display': 'flex', 'flexDirection': 'row', }} className="subtitle-CU"
                              onClick={
                                ()=>{
                                    console.log("clicked");
                                    window.location.href = "tel:587 905 9205";
                                }
                            }
                            >
                            <img src={PhoneIcon} className="mail-icon" />
                                587 905 9205
                            </h3>
                        </div>

                </div>

            </div>
        </div>
    )

}
export default ContactUsView