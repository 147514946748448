import { CircularProgress } from "@mui/material";
import React, { useState, useEffect } from "react";
import './SpinnerView.css'

const SpinnerView = () => {

    return (

        <div className="Spinner_Screen">
            <div className="SubView">
                <CircularProgress />
            </div>
        </div>

    )

}
export default SpinnerView