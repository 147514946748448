import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import "./App.css";
import Vehicle from "./Screens/Vehicle/Vehicle";
import Duration from "./Screens/Duration/Duration";
import CardScreen from "./Screens/CardV2/CardScreen";
import Receipt from "./Screens/SendReceipt/SendReceipt";
import ExtendParking from "./Screens/ExtendParking/ExtendParking";
import HeaderView from "./Components/HeaderView/HeaderView";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useWindowDimensions from "./useWindowDimensions";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { positions } from "@mui/system";
import API from "./Services/API";
import AlertView from './Components/AlertView/AlertView';
import AlertModel from "./Model/AlertModel";
import { useState } from "react";
import Moment from 'react-moment';
import moment from 'moment';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from '../src/Common/AppInsights';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import jwtDecode from "jwt-decode";
import { decodeJWT } from "./Common/utils";
import PopUpView from "./Components/Popup/PopUpView";
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../src/state";
import { DataShareService, DataShareType } from "./Common/DataService";


const theme = createTheme({

  shape: {
    borderRadius: 8,
  },
  spacing: 100,
  palette: {
    primary: {
      light: '#6B7278',
      main: '#00AF85',
      dark: '#6B7278',
      contrastText: '#fff',
    },
    secondary: {
      light: '#6B7278',
      main: '#6B7278',
      dark: '#6B7278',
      contrastText: '#000',
    },
  },

  // components: {

  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         borderRadius: 28,
  //       },
  //     }, 
  //   },
  //   MuiSelect: {
  //     defaultProps: {

  //     }
  //   },

  // },


  typography: {
    fontFamily: ['Manrope'].join(','),
    button: {
      textTransform: 'none'

    },

  },

});

let olderTimerId: NodeJS.Timer | null = null;


function App() {
  var dataAlert: AlertModel
  dataAlert = {}
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();
  const api = new API();
  const [friendlySpotId, setFriendlySpotId] = useState("");
  const [friendlyReservationId, setFriendlyReservationId] = useState("");
  const [reservationGuid, setReservationGuid] = useState("");
  const [alertData, setAlertData] = useState(dataAlert);
  const [ReceiptPopup, setReceiptPopup] = useState(false);
  const dispatch = useDispatch();
  const { addVehicle } = bindActionCreators(actionCreators, dispatch);
  const { addDuration } = bindActionCreators(actionCreators, dispatch);
  const appInsights = useAppInsightsContext();

  const addToAzureAppInsight = (Payload: {
    "User_Guid": string,
    "Action": string,
    "Page": string,
    "Payload"?: any
  }, Type?: string) => {
    try {
      var Payload = Payload;
      var StoreType = Type ? Type : 'Basic'
      if (Payload["User_Guid"] == "") {
        var userguid = decodeJWT(String(localStorage.getItem('Stored-AccessToken')), 'userguid');
        Payload["User_Guid"] = userguid;
      }
      if (StoreType == 'Basic') {
        appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
      }
      if (process.env.REACT_APP_APP_INSIGHT_TYPE == 'Detail' && StoreType == 'Detail') {
        appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
      }
    }
    catch (err) {
      console.log(err)
    }

  }


  const customAlert = () => {
    var Title = (alertData.alertData?.title) ? alertData.alertData?.title : ""
    var SubTitle = (alertData.alertData?.subTitle) ? alertData.alertData?.subTitle : ""
    var ButtonType = (alertData.alertData?.typeButton) ? alertData.alertData?.typeButton : ""
    var BottomTitle = (alertData.alertData?.bottonButton) ? alertData.alertData?.bottonButton : ""

    return (
      alertData.showAlert && (
        // <div dangerouslySetInnerHTML={{__html: SubTitle}}/>
        <AlertView title={Title} subTitle={<div dangerouslySetInnerHTML={{ __html: SubTitle }} />} buttonType={ButtonType} BottomTitle={BottomTitle}
          handleOkay={(value: any) => {
            handelOkayButton()
          }}
          handleNo={(value: any) => {
            handleNo()
          }}
          handleYes={(value: any) => {
            handleYes()
          }}
        />


      )
    )
  }
  const handelOkayButton = () => {
    setAlertData({ showAlert: false })


  }
  const handleNo = () => {
    setAlertData({ showAlert: false })
    addToAzureAppInsight({ User_Guid: "", Action: "alert-no-button", Page: "BackGround" })
    localStorage.removeItem("pending-payment-id")


  }
  const handleYes = () => {
    addToAzureAppInsight({ User_Guid: "", Action: "alert-button-yes-navigate-to-receipt", Page: "BackGround" })
    setAlertData({ showAlert: false })
    localStorage.removeItem("pending-payment-id")
    navigate('/receipt', {
      state:
      {
        friendlySpotId: friendlySpotId,
        friendlyReservationId: friendlyReservationId,
        reservationGuid: reservationGuid
      }
    }
    )


  }



  if (olderTimerId) {
    clearInterval(olderTimerId);
    olderTimerId = null;
  }

  olderTimerId = setInterval(async () => {
    let pendingPaymentId = localStorage.getItem("pending-payment-id");
    let accessToken = sessionStorage.getItem("access-token");
    let timeString = localStorage.getItem("Stored-checkTime") /// Read from local storage
    let now = moment();
    let completedTimeIndex = -1;
    if (timeString) {
      let times = JSON.parse(timeString)
      for (let t = 0; t < times.length; ++t) {
        if (moment(times[t]).isSameOrBefore(now)) {
          completedTimeIndex = t;
        }
        else {
          break;
        }
      }

      if (completedTimeIndex >= 0) {
        let deletedTimes = times.splice(0, completedTimeIndex + 1);
        // console.log("deleted times",deletedTimes)
        localStorage.setItem("Stored-checkTime", JSON.stringify(times));
        // console.log("times.length",times.length);
        if (times.length) {
          /// API CALL;

          localStorage.removeItem("Stored-checkTime")
          // console.log("remove trigged");
          // console.log("pendingPaymentId = ",pendingPaymentId, "accessToken = ",accessToken);
          if (pendingPaymentId && accessToken) {
            addToAzureAppInsight({ User_Guid: "", Action: "check-failed-payment-status", Page: "BackGround" })
            const ConfirmPayment = await api.payment_status(pendingPaymentId, {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': accessToken
            });

            if (ConfirmPayment.friendlyReservationId && ConfirmPayment.friendlySpotId) {
              if (ConfirmPayment.spotGuid && ConfirmPayment.reservationGuid) {
                localStorage.setItem('Stored-spotGuid', ConfirmPayment.spotGuid);
                localStorage.setItem('Stored-reservationGuid', ConfirmPayment.reservationGuid);
              }
              setFriendlySpotId(ConfirmPayment.friendlySpotId);
              setFriendlyReservationId(ConfirmPayment.friendlyReservationId);
              setReservationGuid(ConfirmPayment.reservationGuid);
              setReceiptPopup(true);
              DataShareService.invoke(DataShareType.PaymentCompleted,true)
              
              addToAzureAppInsight({
                User_Guid: "", Action: "failed-payment-got-success-status", Page: "BackGround", Payload: {
                  "PaymentID": localStorage.getItem("pending-payment-id") ? localStorage.getItem("pending-payment-id",
                  ) : "", 'FriendlySpotId': ConfirmPayment.friendlySpotId, 'ReservationGuid': ConfirmPayment.reservationGuid
                }
              })



            }
          }

        }
      }
      else {
        // console.log("No update from payment yet!")
      }

    }
  }, 10000);

  return (

    <ThemeProvider theme={theme}>

      <HeaderView />


      <div className="BaseContainer">
        {
          customAlert()
        }
         {
              ReceiptPopup && (
                  <PopUpView
                      friendlySpotId={friendlySpotId}
                      friendlyReservationId={friendlyReservationId}
                      reservationGuid={reservationGuid}
                      handelClose={
                          () => {
                              setReceiptPopup(false);
                              localStorage.removeItem("pending-payment-id")
                              // addVehicle({});
                              // var DurationDetails = {
                              //     Duration: 0,
                              //     DurationType: "Minutes"
                              // }
                              // addDuration(DurationDetails);
                              // navigate('/');
                          }
                      }
                  >

                  </PopUpView>
              )
          }
        <div className="Max-Width">
          <AppInsightsContext.Provider value={reactPlugin}>
            <Routes>
              <Route path="/" element={<Vehicle />} />
              <Route path="/duration" element={<Duration />} />
              <Route path="/cardscreen" element={<CardScreen />} />
              <Route path="/receipt" element={<Receipt />} />
              <Route path="/ExtendParking" element={<ExtendParking />} />
            </Routes>
          </AppInsightsContext.Provider>

        </div>
      </div>

    </ThemeProvider>
  );

}

export default App;
