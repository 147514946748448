import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Back from '../../Images/back.png';
import LocArrowsvg from '../../Images/locationarrow-svg.svg'
import closesvg from "../../Images/close.svg"
import './Search.css'
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import { bindActionCreators } from 'redux';
import { actionCreators } from "../../state/index";
import { useDispatch } from "react-redux";

function Search(_props: any) {
    const state = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const { addVehicle } = bindActionCreators(actionCreators, dispatch);
    const navigate = useNavigate();
    const VehicleDetails = state.user.VehicleDetails;
    
    const [searchTF, setSearchTF] = useState(state.user.VehicleDetails.propertyName);
    // const [tempPropertyName, setTempPropertyName] = useState(_props.apiPropertyName);
   
    
    useEffect(() => {
        if (_props.ScreenName == "Vehicle" && searchTF != _props.apiPropertyName) {
            //_props.handleParent(searchTF)
        }
        if(searchTF != _props.apiPropertyName ){
            
            setSearchTF(_props.apiPropertyName)
        }
        
        // setTempPropertyName(_props.apiPropertyName)
        // console.log("setTempPropertyName",tempPropertyName)

    }, [searchTF,_props.apiPropertyName])


    const onHandelChange = (e: any) => {
        setSearchTF(e.target.value);
    }


    return (
        <div className="Search-Container">
            <div className="Link-View">
                {_props.ScreenName != "Vehicle" && (
                    <IconButton  style={{ backgroundColor: 'transparent' }} onClick={
                        () => {
                            navigate(-1)
                        }}
                    >
                        <img className="Back-Image" src={Back}></img> <small className="BackButtonText"
                        >Back</small>
                    </IconButton>
                ) || <div></div>}

                {
                    _props.ScreenName == "Vehicle" && (
                        <Link className="Search-Link" to="/ExtendParking">Extend My Parking</Link>
                    ) || (<div></div>)
                }
            </div>
            <div>
                {
                    _props.ScreenName == "ConfirmParking" ? <p className="SearchTitle"> Confirm Parking </p>: <p className="SearchTitle"> Complete Parking Purchase </p>
                }
            </div>
            <div className="SubContainerSearchView">
                {_props.ScreenName == "Vehicle" && (
                    <div className="SearchPropertyInputView">
                        <IconButton>
                            <img className="Arrow-Image" src={LocArrowsvg}></img>
                        </IconButton>
                        <input className="SearchInput" type="text" name="Address" placeholder="Enter Your Location" value={searchTF} onChange={evt => onHandelChange(evt)} />
                        <IconButton onClick={
                            () => {
                                setSearchTF("");
                            }}
                        >
                            <img className="Arrow-Image" src={closesvg}></img>
                        </IconButton>

                    </div>

                ) || (<div className="SearchPropertyInputView-NonEdit">
                    <IconButton>
                        <img className="Arrow-Image" src={LocArrowsvg}></img>
                    </IconButton>
                    <input className="SearchInput" type="text" name="Address" placeholder="Enter Your Location" value={searchTF} onChange={evt => onHandelChange(evt)} readOnly />

                </div>)}
            </div>
            {/* <input type="text" value={searchTF} onChange={evt => onHandelChange(evt)} /> */}
        </div>
    )
}
export default Search