import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import SearchComponent from "../../Components/Search/Search";
import useWindowDimensions from "../../useWindowDimensions";
import { useNavigate, generatePath, useLocation, useParams } from 'react-router-dom';
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux";
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index";
import Back from '../../Images/back.png';
import LocArrowsvg from '../../Images/locationarrow-svg.svg'
import closesvg from "../../Images/close.svg"
import API from "../../Services/API";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { SpinnerCircular } from 'spinners-react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import AlertView from "../../Components/AlertView/AlertView";
import AlertModel from "../../Model/AlertModel";
import Moment from 'react-moment';
import moment from 'moment';
import Popup from "../../Components/Popup/Popup";
import { decodeJWT } from '../../Common/utils';


// import MLink from "@mui/material-ui/core/Link";

import { makeStyles } from '@mui/styles';
import './Vehicle.css'
import ContactUsView from "../../Components/ContactUsView/ContactUs";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import jwtDecode from "jwt-decode";

const useStyles = makeStyles({
    root: {
        '&.MuiAutocomplete-popper': {
            paddingTop: 15
        }
    },
    inputRoot: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#00AF85 !important"
        },
        "&.MuiAutocomplete-popper": {
            paddingTop: 15
        }
    },
    popper: {
        paddingTop: 15
    }

});
const styles = {
    input1: {
        height: 50
    },
    input2: {
        height: 200,
        fontSize: "3em"
    }
};
const Vehicle = (props: any) => {
    // var propertyLocation_:any = []
    var dataAlert: AlertModel
    dataAlert = {}
    const [autocompleteKey, setAutocompleteKey] = useState(0);
    const [firstEntry, setFirstEntry] = useState(true)
    const [alertData, setAlertData] = useState(dataAlert)
    const classes = useStyles();
    const { search } = useLocation();
    const state = useSelector((state: any) => state);
    const VehicleDetails = state.user.VehicleDetails;
    const dispatch = useDispatch();
    const { addVehicle } = bindActionCreators(actionCreators, dispatch);
    const { addDuration } = bindActionCreators(actionCreators, dispatch);
    const navigate = useNavigate();
    const [Make, setMake] = useState(VehicleDetails.make);
    const [Model, setModel] = useState(VehicleDetails.model);
    const [PhoneNumber, setPhoneNumber] = useState(VehicleDetails.phoneNumber ? VehicleDetails.phoneNumber : "")
    const [VehicleColor, setVehicleColor] = useState(VehicleDetails.color);
    const [LicensePlate, setLicensePlate] = useState(VehicleDetails.licensePlate);
    const [PropertyName, setPropertyName] = useState(VehicleDetails.propertyName);
    const [PropertyGuid, setPropertyGuid] = useState(VehicleDetails.propertyGuid);
    const [spinner, SetSpinner] = useState(false);
    const [footerPostion, setFooterPosition] = useState("fixed");
    const [visitorAvaibleSpot, setVisitorAvaibleSpot] = useState(0);
    const [guestAvaibleSpot, setGuestsAvaibleSpot] = useState(0);
    const [showNoVistorPassAlert, setShowNoVistorPassAlert] = useState(false);
    const [os, setOS] = useState("")
    const [link, setLink] = useState("")
    const [showContactUS, SetShowContactUS] = useState(false);
    var percentage = 'fixed';
    const [locations, setLocations] = useState<any>([]);
    const [isTenant, setTenant] = useState(false);
    const [isParking, setParking] = useState(false);
    const appInsights = useAppInsightsContext();
    // const [autoCompleteDV,setAutoCompleteDV] = useState<any>({})
    const api = new API();
    var CurLatitude: number;
    var CurLogitude: number;

    const autoCompleteDV: any = {
        "propertyGuid": PropertyGuid,
        "propertyAddressLine1": PropertyName,
    }

    useEffect(() => {
        addToAzureAppInsight({ User_Guid: "", Action: "current-screen-vehicle", Page: "Vehicle" })
    }, [])

    useEffect(() => {
        if (autocompleteKey == 1) {
            setTimeout(() => {
                setAutocompleteKey(2);
            }, 2000);
        }
    }, [autocompleteKey])

    useEffect(() => {
        // navigate('/receipt', {
        //     state:
        //     {
        //       friendlySpotId: "",
        //       friendlyReservationId: "",
        //     }
        //   }
        //   );
        const platform = window.navigator.platform

        if (platform.toLowerCase().includes('mac') || platform.toLowerCase().includes('iphone') || platform.toLowerCase().includes('ipad')) {
            setLink("https://apps.apple.com/us/app/grydpark/id1529659227");
        } else {
            setLink("https://play.google.com/store/apps/details?id=com.parkwithgryd.grydpark&hl=en_IN&gl=US");
        }
    }


    )
    useEffect(() => {
        CheckandTriggerUnholdSpot();
    }, [])

    // useEffect(()=>{
    //     setAutoCompleteDV({
    //     "propertyGuid": PropertyGuid,
    //     "propertyAddressLine1": PropertyName,
    //     })
    // },[])



    useEffect(() => {
        if (PropertyGuid != 'dntcall') {
            getGuid();
        }

    }, [PropertyGuid, PropertyName])
    const onInputChange = (_event: any, value: string, _reason: any) => {
        if (value) {
            getData(value);
        } else {
            setLocations([]);
        }
    };

    const getData = (searchTerm: string) => {

        setLocations([]);
        if (searchTerm.length >= 3) {
            const searchDetails_ = {
                "SearchKeyword": searchTerm,
                "SearchLatitiude": CurLatitude, //49.905896,,
                "SearchLongitude": CurLogitude, //-97.170653,
                // "PropertyGuid": "" //PropertyGuid,
                //"PropertyGuid": "aeeab98c-2807-4db3-9d68-d3ea305a9356"
            }
            SetSpinner(true);
            api.get_search_property(searchDetails_).then((res) => {
                SetSpinner(false);
                const propertyLocation_ = res
                if (propertyLocation_) {
                    setLocations(propertyLocation_);
                }
            });
        }
    };

    const addToAzureAppInsight = (Payload: {
        "User_Guid": string,
        "Action": string,
        "Page": string,
        "PayLoad"?: any
    }, Type?: string) => {
        try {
            var Payload = Payload;
            var StoreType = Type ? Type : 'Basic'
            if (Payload["User_Guid"] == "") {
                var userguid = decodeJWT(String(localStorage.getItem('Stored-AccessToken')), 'userguid');
                Payload["User_Guid"] = userguid;
            }
            if (StoreType == 'Basic') {
                appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
            }
            if (process.env.REACT_APP_APP_INSIGHT_TYPE == 'Detail' && StoreType == 'Detail') {
                appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
            }
        }
        catch (err) {
            console.log(err);
        }

    }


    const CheckandTriggerUnholdSpot = async () => {
        const tempBlockGuid = sessionStorage.getItem('Temp-blockGuid')
        //console.log("tempBlockGuid", tempBlockGuid);
        if (tempBlockGuid && tempBlockGuid != 'null') {
            const unhold_spot = await api.unhold_spot(tempBlockGuid ? tempBlockGuid : "", {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
            })
            if (unhold_spot == 200) {
                sessionStorage.setItem('Temp-blockGuid', 'null');
                addToAzureAppInsight({
                    User_Guid: "", Action: `unhold-spot`, Page: "Vehicle", PayLoad: {
                        'TempBlockGuid': tempBlockGuid
                    }
                }, 'Detail');
            }
        }

    }
    const customAlert = () => {
        var Title = (alertData.alertData?.title) ? alertData.alertData?.title : ""
        var SubTitle = (alertData.alertData?.subTitle) ? alertData.alertData?.subTitle : ""
        var ButtonType = (alertData.alertData?.typeButton) ? alertData.alertData?.typeButton : ""
        var BottomTitle = (alertData.alertData?.bottonButton) ? alertData.alertData?.bottonButton : ""
        // console.log("======IsTenant====" + isTenant);

        return (
            alertData.showAlert && (
                // <div dangerouslySetInnerHTML={{__html: SubTitle}}/>
                <AlertView title={Title} subTitle={<div dangerouslySetInnerHTML={{ __html: SubTitle }} />} buttonType={ButtonType} BottomTitle={BottomTitle}
                    handleOkay={(value: any) => {
                        handelOkayButton()
                    }}
                    handleNo={(value: any) => {
                        handleNo()
                    }}
                    handleYes={(value: any) => {
                        handleYes()
                    }}
                />


            )
        )
    }
    const Contactus = () => {
        return (
            <ContactUsView
                handelClose={
                    (value: any) => {
                        SetShowContactUS(false);
                    }
                }
            ></ContactUsView>
        )
    }
    const noVistorPassAlert = () => {
        return (

            <AlertView title={'Parking Unavailable'} subTitle={<div>
                <small style={{ 'fontFamily': 'Manrope', 'fontSize': '15px', 'fontWeight': 400, 'color': '#323232' }}>
                    This tenant used the maximum number of <br />
                    visitor parking passes allowed for this <br />
                    month. For help please <small style={{ 'fontFamily': 'Manrope', 'fontSize': '15px', 'fontWeight': 400, 'textDecorationLine': 'underline', 'color': '#00AF85' }}
                        onClick={
                            () => {
                                setShowNoVistorPassAlert(false);
                                SetShowContactUS(true);
                                addToAzureAppInsight({ User_Guid: "", Action: "contact-us", Page: "Vehicle" })

                            }
                        }
                    >
                        contact us
                    </small>
                </small>
            </div>} buttonType={'single'} BottomTitle={''}
                handleOkay={(value: any) => {
                    setShowNoVistorPassAlert(false)
                }}

            />
        )
    }


    const handleNo = () => {
        addToAzureAppInsight({ User_Guid: "", Action: "visitor-parking?-alert_no", Page: "Vehicle" })
        setAlertData({ showAlert: false })
        setTenant(false);
        if (guestAvaibleSpot <= 0) {
            addToAzureAppInsight({ User_Guid: "", Action: "visitor-parking?-alert-no-after-no-guest-parking", Page: "Vehicle" })
            setAlertData({
                showAlert: true, alertData: {
                    title: "Parking Unavailable",
                    subTitle: `This lot is for visitor parking only.`,
                    bottonButton: ''
                }
            })
        } else {
            navigate('/duration');
        }

    }
    const handleYes = () => {
        setAlertData({ showAlert: false })
        addToAzureAppInsight({ User_Guid: "", Action: "visitor-parking?-alert-yes-button", Page: "Vehicle" })
        if (alertData.alertData?.title == "Visitor Parking?") {
            setTenant(true)
            alertTenant()
        }
    }

    const handleContinue = async (unitNumber: string, passCode: string) => {
        addToAzureAppInsight({ User_Guid: "", Action: "tenant-verification-continue-button", Page: "Vehicle" }, 'Detail')

        if (unitNumber.length > 0 && passCode.length > 0) {
            setTenant(false)
            api.visitor_verify_tenant({
                "UnitNumber": unitNumber,
                "PassCode": passCode,
                "PropertyGuid": PropertyGuid ? PropertyGuid : state.user.VehicleDetails.propertyGuid
            }).then(async (result: any) => {
                var data = JSON.parse(result)
                if (data.status == 200) {
                    addToAzureAppInsight({ User_Guid: "", Action: "tenant-verification-continue", Page: "Vehicle" }, 'Detail')
                    localStorage.setItem('UnitGuid', data.response ? data.response : "");
                    navigate('/duration');
                } else if (data.status == 400) {
                    if (data.response == "Received Maximum") {
                        addToAzureAppInsight({ User_Guid: "", Action: "tenant-verification-received-maximum", Page: "Vehicle" }, 'Detail')
                        setShowNoVistorPassAlert(true)
                    } else {
                        addToAzureAppInsight({ User_Guid: "", Action: `error-tenant-verification`, Page: "Vehicle", PayLoad: { 'error': data.response } }, 'Detail')
                        setAlertData({
                            showAlert: true, alertData: {
                                title: "Parking Unavailable",
                                subTitle: data.response,
                                typeButton: 'single',
                                bottonButton: ''
                            }
                        })
                    }
                }
            })
        }
    }
    const handelOkayButton = () => {
        setAlertData({ showAlert: false })
        // setPropertyName("")
        // setPropertyGuid("");
        // setAutoCompleteDV({
        //     "propertyGuid": PropertyGuid,
        //     "propertyAddressLine1": PropertyName,
        //     })


    }
    const alertTenant = () => {
        var Title = "Tenant Verification"
        var SubTitle = "Please enter the unit number and \n passcode of the the tenant you are visiting.";
        return (
            isTenant && (
                <Popup title={Title} subTitle={SubTitle}
                    propertyGuid={PropertyGuid}
                    handleContinue={(value_1: any, value_2: any) => {
                        handleContinue(value_1, value_2)
                    }}
                    handelClose={() => {
                        setTenant(false)
                        setAlertData({
                            showAlert: true, alertData: {
                                title: "Visitor Parking?",
                                subTitle: `The lot is for visitor parking only, are you visiting a tenant at this address?`,
                                typeButton: "dual",
                            }
                        })

                    }}
                    isTenant={isTenant} />
            )
        )

    }

    const getLocation = async () => {
        navigator.geolocation.getCurrentPosition(
            position => {

                CurLatitude = position.coords.latitude;
                CurLogitude = position.coords.longitude;

                tiggerApi();
            },
            err => console.log(err)
        );

    }

    const getGuid = async () => {

        if (search) {
            if (!state.user.VehicleDetails.propertyGuid) {
                let wasFirstEntry = firstEntry;
                if (firstEntry) {
                    setFirstEntry(false);
                    setPropertyGuid(search.replace('?p=', ''))
                }
                await tiggerApi();
                if (wasFirstEntry) {
                    setAutocompleteKey(1);
                }
            }
        }


    }

    const tiggerApi = async () => {
        SetSpinner(true);
        if (PropertyGuid && PropertyGuid != "dntcall") {
            let response: any = await api.get_search_property({
                // "SearchLatitiude": CurLatitude,
                // "SearchLongitude": CurLogitude,
                "PropertyGuid": PropertyGuid,
            });

            SetSpinner(false)
            if (response != null) {
                response.forEach((obj: any) => {
                    // const result: any = JSON.stringify(response[0]);
                    // if(PropertyName){
                    setPropertyName(obj["propertyAddressLine1"]);
                    const VehicleDetails = {
                        propertyName: obj["propertyAddressLine1"],
                        make: Make,
                        model: Model,
                        color: VehicleColor,
                        licensePlate: LicensePlate,
                        propertyGuid: PropertyGuid,
                        curLatitude: CurLatitude,
                        curLogitude: CurLogitude,
                        phoneNumber: PhoneNumber
                    }
                    addVehicle(VehicleDetails);
                })
            }
        }
        else {
            SetSpinner(false);
        }

    }
    const propertySearchField = async (event: any, value: string) => {
        const index = event.currentTarget.getAttribute("data-option-index");
        setLocations([]);
        setPropertyName("");
        setPropertyGuid("");
        if (event.target && event.target.getAttribute("role") == "option" && (event.target.value === 0 || event.target.value > 0)) {
            if (locations && locations.length > 0 && locations[event.target.value]["propertyAddressLine1"]) {
                setPropertyName(locations[index]["propertyAddressLine1"])
                setPropertyGuid(locations[index]["propertyGuid"]);

                api.property_availability({
                    "DurationInMinutes": 15,
                    "PropertyGuid": locations[index]["propertyGuid"],
                    "IsGuestAndVisitor": true

                }).then((result: any) => {
                    var data = JSON.parse(result)

                    //here to write logic
                    if (data.response.availableGuestCount) {
                        setGuestsAvaibleSpot(data.response.availableGuestCount);
                    }
                    if (data.response.availableVisitorCount) {
                        setVisitorAvaibleSpot(data.response.availableVisitorCount);
                    }

                    if (data.response.hasAvailableSpots) {

                    } else {
                        addToAzureAppInsight({ User_Guid: "", Action: `no-guest-and-visitor-available-property-guid`, Page: "Vehicle", PayLoad: { 'propertyGuid': locations[index]["propertyGuid"] } }, 'Detail')
                        setAlertData({
                            showAlert: true, alertData: {
                                title: "This Lot is Full",
                                subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                bottonButton: `I see empty spots, why can’t I book? `
                            }
                        })
                    }
                })
            }
        }
        else {
            if (value.length >= 3) {
                const searchDetails_ = {
                    "SearchKeyword": value,
                    "SearchLatitiude": CurLatitude, //49.905896,,
                    "SearchLongitude": CurLogitude, //-97.170653,
                    // "PropertyGuid": "" //PropertyGuid,
                    //"PropertyGuid": "aeeab98c-2807-4db3-9d68-d3ea305a9356"
                }
                SetSpinner(true);
                const propertyLocation_ = await api.get_search_property(searchDetails_);
                SetSpinner(false);
                if (propertyLocation_) {
                    setLocations(propertyLocation_);
                }
            }
        }
    }
    const query = new URLSearchParams(search);
    const paramField = query.get('field');
    const paramValue = query.get('value');
    const { id } = useParams();

    let dataSource = (locations && locations.length) ?
        locations
        : [];
    
    if (PropertyGuid && !dataSource.length) {
        dataSource.push({
            "propertyGuid": PropertyGuid,
            "propertyAddressLine1": PropertyName,
        } as any);
    }

    return (
        <div className="VehicleBaseContiner">
            {
                spinner && (
                    <SpinnerView />
                )
            }
            {
                customAlert()
            }
            {
                showNoVistorPassAlert && (
                    noVistorPassAlert()
                )
            }
            {
                isTenant && alertTenant()
            }
            {
                showContactUS &&
                (
                    Contactus()
                )
            }

            <div className="Search-ContainerV2">
                <div className="Link-View">
                    <Link className="Search-Link" to=""></Link>
                    <IconButton
                        style={{ backgroundColor: 'transparent' }}
                        onClick={
                            () => {
                                addToAzureAppInsight({ User_Guid: "", Action: "extend", Page: "Vehicle" })
                                if (localStorage.getItem('last-booking-details') && localStorage.getItem('Stored-AccessToken') && localStorage.getItem('Stored-reservationGuid') && localStorage.getItem('last-booking-details') != "{}") {
                                    setTimeout(() => {
                                        let storedVehicledetails = JSON.parse(localStorage.getItem('last-booking-details') || '{}')
                                        const VehicleDetails = {
                                            propertyName: storedVehicledetails.propertyName,
                                            make: storedVehicledetails.make,
                                            model: storedVehicledetails.model,
                                            color: storedVehicledetails.color,
                                            licensePlate: storedVehicledetails.licensePlate,
                                            propertyGuid: storedVehicledetails.propertyGuid,
                                            curLatitude: storedVehicledetails.curLatitude,
                                            curLogitude: storedVehicledetails.curLongitude,
                                            phoneNumber: storedVehicledetails.phoneNumber
                                        }
                                        addVehicle(storedVehicledetails);
                                        var DurationDetails = {
                                            Duration: 0,
                                            DurationType: "Minutes"
                                        }
                                        addDuration(DurationDetails);
                                        addToAzureAppInsight({ User_Guid: "", Action: "extend_navigate_to_duration", Page: "Vehicle" })
                                        navigate('/duration', {
                                            state:
                                            {
                                                Via: 'Extend'
                                            }
                                        });

                                    }, 1000);

                                } else {
                                    setAlertData({
                                        showAlert: true, alertData: {
                                            title: "GrydPark",
                                            subTitle: "No active booking to extend",
                                        }
                                    })
                                }

                            }}
                    >
                        <small className="BackButtonText"
                        >Extend My Parking</small>
                    </IconButton>

                </div>
                <div>
                    <p className="SearchTitle"> Complete Parking Purchase </p>
                </div>
                <div className="SubContainerSearchView">
                    {/* <Autocomplete
                        id="free-solo-demo"
                        onClose={(event, newInputValue) => {
                            console.log("onClose called");
                            propertySearchField(event, newInputValue)
                        }}
                        freeSolo
                        options={locations.map((option: any) => option.propertyAddressLine1)}
                        clearIcon={<img className="Arrow-Image" src={closesvg}></img>}
                        classes={{ ...classes, option: classes.popper }}
                        noOptionsText={true}
                        componentsProps={{
                            paper: {
                                sx: {
                                    borderRadius: "12px",
                                }
                            },
                        }}
                        ListboxProps={
                            {
                                style: {
                                    border: '1px solid #8A959E',
                                    borderRadius: "12px",
                                    lineHeight: "20px",
                                }
                            }
                        }
                        renderInput={(params) =>
                            <TextField
                                className={"MatrialTextFieldFull"}
                                placeholder="Enter Your Location"
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img className="Arrow-Image" src={LocArrowsvg}></img>
                                        </InputAdornment>
                                    )
                                }}
                            />}
                        value={PropertyName}
                        onChange={(event, newInputValue) =>{
                            console.log('onChange',event,newInputValue);

                        }}
                        onInputChange={(event, newInputValue) =>{ 
                            console.log('onInputChange',event,newInputValue);
                            propertySearchField(event, newInputValue)}}
                    />   */}

                    {
                        autocompleteKey != 1 ?
                        <Autocomplete
                            key={autocompleteKey}
                            freeSolo
                            id="combo-box-demo"
                            options={dataSource}
                            defaultValue={dataSource[0]}
                            onInputChange={onInputChange}
                            getOptionLabel={(option: any) => option.propertyAddressLine1 ? option.propertyAddressLine1 : ''}
                            onChange={(event, selectValue) => {
                                //console.log("selectValue", selectValue);
                                setPropertyName('')
                                setPropertyGuid('');
                                if (selectValue) {
                                    api.property_availability({
                                        "DurationInMinutes": 15,
                                        "PropertyGuid": selectValue.propertyGuid,
                                        "IsGuestAndVisitor": true

                                    }).then((result: any) => {
                                        var data = JSON.parse(result)
                                        setPropertyName(selectValue.propertyAddressLine1)
                                        setPropertyGuid(selectValue.propertyGuid);

                                        //here to write logic
                                        if (data.response.availableGuestCount) {
                                            setGuestsAvaibleSpot(data.response.availableGuestCount);
                                        }
                                        if (data.response.availableVisitorCount) {
                                            setVisitorAvaibleSpot(data.response.availableVisitorCount);
                                        }

                                        if (data.response.hasAvailableSpots) {

                                        } else {
                                            addToAzureAppInsight({ User_Guid: "", Action: `no-guest-and-visitor-available-property-guid`, Page: "Vehicle", PayLoad: { 'propertyGuid': selectValue.propertyGuid } }, 'Detail')
                                            setAlertData({
                                                showAlert: true, alertData: {
                                                    title: "This Lot is Full",
                                                    subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                                    bottonButton: `I see empty spots, why can’t I book? `
                                                }
                                            })
                                        }
                                    })
                                }
                            }
                            }
                            clearIcon={<img className="Arrow-Image" style={{ backgroundColor: 'transparent' }} src={closesvg}></img>}
                            componentsProps={{
                                paper: {
                                    sx: {
                                        marginTop: '10px',
                                        borderRadius: "12px",

                                    }
                                },
                            }}
                            ListboxProps={
                                {
                                    style: {
                                        border: '1px solid #8A959E',
                                        borderRadius: "12px",
                                        lineHeight: "20px",

                                    }
                                }
                            }
                            renderInput={(params) =>
                                <TextField
                                    className={"MatrialTextFieldFull"}
                                    placeholder="Enter Your Location"
                                    value={'apple'}
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img className="Arrow-Image" src={LocArrowsvg}></img>
                                            </InputAdornment>
                                        )
                                    }}
                                />}
                        />
                        : <Autocomplete
                                key={autocompleteKey}
                                disabled={true}
                                componentsProps={{
                                    paper: {
                                        sx: {
                                            marginTop: '10px',
                                            borderRadius: "12px",
                                        }
                                    },
                                }}
                                renderInput={(params) => <TextField
                                    className={"MatrialTextFieldFull"}
                                    placeholder="Enter Your Location"
                                    value={'apple'}
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img className="Arrow-Image" src={LocArrowsvg}></img>
                                            </InputAdornment>
                                        )
                                    }} />}
                                value={null}
                                defaultValue={null}
                                options={[]}
                            />
                    }
                </div>
            </div>
            <div className="Vehicle-InputView">

                <div style={{ 'marginTop': '25px' }}>
                    <div className="SubContainerVechicleView">
                        <h2 className="SubTitleLbl">{`Enter Vehicle Information`}</h2>
                        <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" size="medium" label="Make" variant="outlined" color="primary" autoComplete="off" value={Make}
                            inputProps={{ maxLength: 30 }}
                            onChange={(e) => {
                                setMake(e.target.value)
                            }}
                            onFocus={(e) => {

                                setFooterPosition("relative")
                            }}
                            onBlur={(e) => {
                                setFooterPosition("fixed")

                            }}

                        />
                    </div>
                </div>
                <div className="SubContainerVechicleView">
                    <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" label="Model" variant="outlined" color="primary" autoComplete="off" value={Model}
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => {
                            setModel(e.target.value)
                        }}
                        onFocus={(e) => {

                            setFooterPosition("relative")
                        }}
                        onBlur={(e) => {
                            setFooterPosition("fixed")

                        }}
                    />
                </div>
                <div className="SubContainerRowVechicleView">
                    <div className="HalfTextFieldFirstParent">
                        <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" label="Vehicle Color" variant="outlined" autoComplete="off" color="primary" value={VehicleColor}
                            inputProps={{ maxLength: 30 }}
                            onChange={(e) => {
                                setVehicleColor(e.target.value)
                            }}
                            onFocus={(e) => {

                                setFooterPosition("relative")
                            }}
                            onBlur={(e) => {
                                setFooterPosition("fixed")

                            }}
                        />
                    </div>
                    <div className="HalfTextFieldLastParent">
                        <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" label="License Plate" variant="outlined" color="primary" autoComplete="off" value={LicensePlate}
                            inputProps={{ maxLength: 9 }}
                            onChange={(e) => {
                                setLicensePlate(e.target.value)
                            }}
                            onFocus={(e) => {

                                setFooterPosition("relative")
                            }}
                            onBlur={(e) => {
                                setFooterPosition("fixed")

                            }}
                        />
                    </div>
                </div>
                <div style={{ 'marginTop': '25px' }}>
                    <div className="SubContainerVechicleView">
                        <h2 className="SubTitleLbl">{`Enter Phone Number`}</h2>
                        <TextField fullWidth className="MatrialTextFieldFull" id="outlined-basic" label="Phone Number" variant="outlined" color="primary" autoComplete="off" value={PhoneNumber}
                            inputProps={{
                                maxLength: 20,

                            }}
                            onChange={(e) => {
                                setPhoneNumber(e.target.value)
                            }}
                            onFocus={(e) => {

                                setFooterPosition("relative")
                            }}
                            onBlur={(e) => {
                                setFooterPosition("fixed")

                            }}
                        />
                        <div style={{ 'marginTop': '10px' }}>
                            <small style={{ 'fontSize': '12px', fontFamily: "Manrope", fontWeight: '400' }}>Phone number is required for operational messages or if we need to reach you about your parking.</small>
                        </div>
                    </div>
                </div>
            </div>



            <div className="Next-View-Vehicle">
                <h4 className="NoteText"></h4>
                <div style={{ 'marginTop': '30px' }}>
                    <Button style={LicensePlate && Make && Model && VehicleColor && PropertyName && PhoneNumber.length >= 10 ? { width: '100%', height: '48px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' } : { width: '100%', height: '48px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="contained" onClick={
                        async () => {
                            setTimeout(async () => {
                                if (Make && Model && LicensePlate && VehicleColor && PropertyName && PhoneNumber.length >= 10) {
                                    const VehicleDetails = {
                                        propertyName: PropertyName,
                                        make: Make,
                                        model: Model,
                                        color: VehicleColor,
                                        licensePlate: LicensePlate,
                                        phoneNumber: PhoneNumber,
                                        propertyGuid: PropertyGuid ? PropertyGuid : state.user.VehicleDetails.propertyGuid,
                                        curLatitude: CurLatitude,
                                        curLongitude: CurLogitude
                                    }
                                    addToAzureAppInsight({ User_Guid: "", Action: "next-button", "Page": "Vehicle" });
                                    addVehicle(VehicleDetails)
                                    SetSpinner(true);
                                    api.property_availability({
                                        "DurationInMinutes": 15,
                                        "PropertyGuid": VehicleDetails.propertyGuid,
                                        "IsGuestAndVisitor": true

                                    }).then(async (result: any) => {
                                        SetSpinner(false);
                                        var data = JSON.parse(result)

                                        //here to write logic
                                        if (data.response.availableGuestCount) {
                                            setGuestsAvaibleSpot(data.response.availableGuestCount);
                                        }
                                        if (data.response.availableVisitorCount) {
                                            setVisitorAvaibleSpot(data.response.availableVisitorCount);
                                        }

                                        if (data.response.hasAvailableSpots) {
                                            SetSpinner(true);
                                            localStorage.removeItem("UnitGuid");
                                            const isVisitorParking = await api.get_visitor_verification(PropertyGuid ? PropertyGuid : state.user.VehicleDetails.propertyGuid, {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json',
                                            })
                                            SetSpinner(false);
                                            if (isVisitorParking) {
                                                setAlertData({
                                                    showAlert: true, alertData: {
                                                        title: "Visitor Parking?",
                                                        subTitle: `The lot is for visitor parking only, are you visiting a tenant at this address?`,
                                                        typeButton: "dual",
                                                    }
                                                })
                                            } else {
                                                navigate('/duration');
                                            }
                                        } else {
                                            setAlertData({
                                                showAlert: true, alertData: {
                                                    title: "This Lot is Full",
                                                    subTitle: `Unfortunately, there is no available parking at this location currently.`,
                                                    bottonButton: `I see empty spots, why can’t I book? `
                                                }
                                            })
                                        }
                                    })
                                    //remove unitguid

                                } else {
                                    setAlertData({
                                        showAlert: true, alertData: {
                                            title: "",
                                            subTitle: "Please complete all of the fields.",
                                            // typeButton:"dua"
                                        }
                                    })
                                }
                            }, 500);
                        }}>Next</Button>
                </div>

            </div>
        </div>


    )
}

export default Vehicle


