const VehicleDetails = {
    propertyName: "",
    make: "",
    model: "",
    color: "",
    licensePlate: "",
    phoneNumber: "",
    propertyGuid: "",
    curLatitude: "",
    curLongitude: ""
}
const DurationDetails = {
    Duration: "",
    DurationType: ""
}
const HourlyDetails = {
    rateType: "",
    hourRate: "",
    day: "",
    startHour: "",
    endHour: "",
    totalStartHour: "",
    totalEndHour: "",
    hours: "",
    totalPrice: "",
    totalSubsetPrice: "",
    isCappedAtDailyPrice: false,
    fixedRate: "",
    subset: ""
}

const User = {
    VehicleDetails: VehicleDetails,
    DurationDetails: DurationDetails,
    HourlyDetails: HourlyDetails

}

const reducer = (
    state = User,
    action: any
) => {
    switch (action.type) {
        case "addVehicleDetails":
            var data = action.payload
            VehicleDetails["propertyName"] = data.propertyName;
            VehicleDetails["make"] = data.make;
            VehicleDetails["model"] = data.model;
            VehicleDetails["color"] = data.color;
            VehicleDetails["licensePlate"] = data.licensePlate;
            VehicleDetails["phoneNumber"] = data.phoneNumber;
            VehicleDetails["propertyGuid"] = data.propertyGuid;
            VehicleDetails["curLatitude"] = data.curLatitude;
            VehicleDetails["curLongitude"] = data.curLongitude;
            User["VehicleDetails"] = VehicleDetails

            return User

        case "addDurationDetails":
            var data = action.payload
            DurationDetails["Duration"] = data.Duration;
            DurationDetails["DurationType"] = data.DurationType;
            User["DurationDetails"] = DurationDetails
            return User

        case "addHourlyDetails":
            var data = action.payload
            HourlyDetails["rateType"] = data.rateType;
            HourlyDetails["hourRate"] = data.hourRate;
            HourlyDetails["day"] = data.day;
            HourlyDetails["startHour"] = data.startHour;
            HourlyDetails["endHour"] = data.endHour;
            HourlyDetails["totalStartHour"] = data.totalStartHour;
            HourlyDetails["totalEndHour"] = data.totalEndHour;
            HourlyDetails["hours"] = data.hours;
            HourlyDetails["totalPrice"] = data.totalPrice;
            HourlyDetails["totalSubsetPrice"] = data.totalSubsetPrice;
            HourlyDetails["isCappedAtDailyPrice"] = data.isCappedAtDailyPrice;
            HourlyDetails["fixedRate"] = data.fixedRate;
            HourlyDetails["subset"] = data.subset;
            User["HourlyDetails"] = HourlyDetails
            return User

        default:
            return state
    }
}

export default reducer;