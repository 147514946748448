
export default interface dispatchModel {
   type:string,
   payload:any
 }
 
export const addVehicle = (data:any) => {
    return (dispatch:any) => {
        dispatch({
            type: "addVehicleDetails",
            payload: data
        });
    }
}

export const addDuration = (data:any) => {
    return (dispatch:any) => {
        dispatch({
            type: "addDurationDetails",
            payload: data
        });
    }
}

export const addHourly = (data:any) => {
    return (dispatch:any) => {
        dispatch({
            type: "addHourlyDetails",
            payload: data
        });
    }
}