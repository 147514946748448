import jwtDecode from "jwt-decode";

export const decodeJWT = (encoded: string, extractKey?: string) => {
    if (encoded && encoded != 'null' && encoded != "") {
        if (extractKey && extractKey != 'null' && extractKey != "") {
                var decodedObj: any = jwtDecode(encoded);
                return decodedObj[extractKey];
        } else {
            return jwtDecode(encoded);
        }
    } else {
        return ""
    }
};