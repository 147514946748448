// Service
export class DataShareService {
	// public static subscribers:{
	// 	[id:number]:(()=> void)[]
	// } = {};

	// static emit(event_: DataShareType) {
	// 	if (DataShareService.subscribers[event_]) {
	// 		for (let subscriber of DataShareService.subscribers[event_]) {
	// 			subscriber();
	// 		}
	// 	}
	// }
	// static subscribe(event_: DataShareType, subscriber_: () => void) {
	// 	console.log("trigger subscribe")
	// 	if (!DataShareService.subscribers[event_]) {
	// 		DataShareService.subscribers[event_]=[];
	// 	}

	// 	DataShareService.subscribers[event_].push(subscriber_);
	// }
	// static unSubscribe(event_: DataShareType, subscriber_: () => void) {
	// 	console.log("trigger unSubscribe")
	// 	if (DataShareService.subscribers[event_]) {
	// 		let subIndex = DataShareService.subscribers[event_].indexOf(subscriber_);
	// 		DataShareService.subscribers[event_].splice(subIndex, 1);
	// 	}
	// }
	private static eventHandlers: {
		[event_: number]: ((params_: any) => void)[]
	} = {};

	public static subscribe(eventType_: DataShareType, handler_: (params_: any) => void): void {
		if (!DataShareService.eventHandlers[eventType_]) {
			DataShareService.eventHandlers[eventType_] = [];
		}

		DataShareService.eventHandlers[eventType_].push(handler_);
	}

	public static invoke(eventType_: DataShareType, params_: any): void {
		if (DataShareService.eventHandlers[eventType_]) {
			for (let eventHandler of DataShareService.eventHandlers[eventType_]) {
				eventHandler(params_);
			}
		}
	}
}

export enum DataShareType {
	PaymentCompleted
}

// /// Sorry Popup modal
// DataShareService.subscribe(); => close the modal
// DataShareService.unSubscribe();

// /// Payment complete bottom sheet
// DataShareService.emit(DataShareType.PaymentCompleted);
