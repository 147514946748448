import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({ basename: '' });
export var reactPlugin = new ReactPlugin();
export const instrumentationKey = process.env.REACT_APP_APP_INSIGHT;
// console.log("instrumentationKey",instrumentationKey);
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});
appInsights.loadAppInsights();
