import { Button, TextField } from "@mui/material";
import react, { useState, useEffect } from "react";
import "./SendReceipt.css"
import CheckMark from "../../Images/Checkmark.svg";
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import AlertModel from "../../Model/AlertModel";
import AlertView from "../../Components/AlertView/AlertView";
import API from "../../Services/API";
import Pdf from "react-to-pdf";
import gryd from '../../Images/gryd_logo.svg';
import tick from '../../Images/tick.svg'
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import linkedin from '../../Images/linkedin.png'
import { bindActionCreators } from 'redux'
import { actionCreators } from "../../state/index";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SpinnerView from "../../Components/SpinnerView/SpinnerView";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { decodeJWT } from "../../Common/utils";


function SendReceipt(_props) {
  var dataAlert
  dataAlert = {}
  const [alertData, setAlertData] = useState(dataAlert);
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [showDownload, setShowDownload] = useState(false);
  // const [friendlySpotId, setFriendlySpotId] = useState(location.state.friendlySpotId?location.state.friendlySpotId:"");
  // const [reservationGuid, setReservationGuid] = useState(location.state.reservationGuid?location.state.reservationGuid:"");
  const [friendlySpotId, setFriendlySpotId] = useState(_props.friendlySpotId);
  const [reservationGuid, setReservationGuid] = useState(_props.reservationGuid);
  const [footerPostion, setFooterPosition] = useState("fixed");
  const [showPDF, setShowPDF] = useState(true);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { addVehicle } = bindActionCreators(actionCreators, dispatch);
  const { addDuration } = bindActionCreators(actionCreators, dispatch);
  const [parkingId, setParkingId] = useState("");
  const [parkingSpot, setParkingSpot] = useState("");
  const [parkinglocation, setParkingLocation] = useState("");
  const [parkingStartTime, setParkingStartTime] = useState("");
  const [parkingEndTime, setParkingEndTime] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [parkingFee, setParkingFee] = useState(0.00);
  const [transactionFee, setTransactionFee] = useState(0.00);
  const [GST, setGST] = useState(0.00);
  const [total, setTotal] = useState(0.00);
  // const [isletExtend, setIsLetExtend] = useState(false);
  const [spinner, SetSpinner] = useState(false);
  let IsLetExtendv2 = false;
  const appInsights = useAppInsightsContext();
  const ref = react.createRef();
  const api = new API()
  const navigate = useNavigate();

  // console.log("Actual start month 123", moment().format("MM/DD/YYYY"));
  useEffect(() => {
    addToAzureAppInsight({ User_Guid: "", Action: "current-screen-send-receipt", Page: "send-receipt" })
  }, [])
  useEffect(() => {
    getPaymentDetail();

  }, [reservationGuid])
  // Email validation state changes
  useEffect(() => {

    validateEmail(email)

  }, [email])


  // Phone number 
  useEffect(() => {
    window.onpopstate = function () {
      window.history.go(1);

      addVehicle({});
      var DurationDetails = {
        Duration: 0,
        DurationType: "Minutes"
      }
      addDuration(DurationDetails);
      navigate('/');
    };

  }, [phoneNumber])

  //   useEffect(() => {
  //     window.onbeforeunload = function () {
  //       return true;
  //     };

  //     return () => {
  //       window.onbeforeunload = null;
  //     };
  //   });
  //   useEffect(() => {
  //     window.history.pushState(null, "", window.location.pathname);
  //     window.addEventListener('close', onBackButtonEvent);
  //     return () => {
  //         window.removeEventListener('close', onBackButtonEvent);
  //     };

  // }, []);

  //   const [finishStatus, setfinishStatus] = useState(false);

  //   const onBackButtonEvent = (e) => {

  //     e.preventDefault();
  //     console.log("here to write logic");
  //     if (!finishStatus) {





  //     }
  //   }

  //   useEffect(() => {
  //     window.history.pushState(null, "", window.location.pathname);
  //     window.addEventListener('popstate', onBackButtonEvent);
  //     return () => {
  //       window.removeEventListener('popstate', onBackButtonEvent);
  //     };
  //   }, []);

  const addToAzureAppInsight = (Payload, Type) => {
    try {
      var Payload = Payload;
      var StoreType = Type ? Type : 'Basic'
      if (Payload["User_Guid"] == "") {
        var userguid = decodeJWT(String(localStorage.getItem('Stored-AccessToken')), 'userguid');
        Payload["User_Guid"] = userguid;
      }
      if (StoreType == 'Basic') {
        appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
      }
      if (process.env.REACT_APP_APP_INSIGHT_TYPE == 'Detail' && StoreType == 'Detail') {
        appInsights.trackEvent({ name: `Guest-CheckOut-${Payload.Action}`, properties: Payload })
      }

    }
    catch (err) {
      console.log(err);
    }
  }


  const getPaymentDetail = async () => {
    const paymentDetails = await api.get_booking_details(reservationGuid, {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
    })

    if (paymentDetails.length > 0) {
      let total = 0.00;
      let gst = 0.00;
      let transactionFee = 0.00;
      let parkingFee = 0.00;
      let scheduledStartDate = "";

      for (var i = 0; i < paymentDetails.length; i++) {
        total = total + paymentDetails[i].paidAmount;
        gst = gst + paymentDetails[i].tax;
        transactionFee = transactionFee + paymentDetails[i].serviceCharge;
        parkingFee = parkingFee + paymentDetails[i].bookingAmount;
        scheduledStartDate = paymentDetails[i].scheduledStartDate;
      }
      let Details = paymentDetails[0];
     //console.log("scheduledStartDate", Details.serviceCharge);
     // console.log("tax", Details.tax);

      setParkingId(Details.friendlyReservationId);
      setParkingSpot(Details.friendlySpotId);
      setParkingLocation(Details.propertyAddressLine1);
      setParkingStartTime(moment(scheduledStartDate).format("MM/DD/YYYY hh:mm:A"));
      setParkingEndTime(moment(Details.scheduledEndDate).format("MM/DD/YYYY hh:mm:A"));
      setLicensePlate(Details.vehicleLicense);
      setPaymentMethod(`${Details.cardBrand ? Details.cardBrand : ""} **** ${Details.cardLast4 ? Details.cardLast4 : ""}`);
      setParkingFee(parkingFee);
      setTransactionFee(transactionFee);
      setGST(gst);
      setTotal(total);
    }


  }


  const generateBillPDF = () => {

    const options = {
      orientation: 'portrait',
      unit: 'px',
      format: [1120, 800]
    };
    // const options = {
    //   orientation: 'portrait',
    //   unit: 'in',
    //   format: [13.5, 10.8]
    // };

    return (
      <div>
        <Pdf targetRef={ref} filename="Receipt.pdf" options={options} x={0.0} y={0.0} scale={1.8} >
          {({ toPdf }) => <Button style={{ width: '100%', height: '50px', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', marginTop: 10, borderColor: "#00AF85", color: "#00AF85", borderRadius: '12px' }} variant="outlined" onClick={() => {
            setTimeout(() => {
              toPdf()
              setShowPDF(false)
              addToAzureAppInsight({ User_Guid: "", Action: `download-pdf`, Page: "SendReceipt" });
            }, 500)
            setShowPDF(true)
          }
          }>Download Receipt</Button>}
        </Pdf>
      </div>
    );
  }

  const customAlert = () => {
    var Title = (alertData.alertData?.title) ? alertData.alertData?.title : ""
    var SubTitle = (alertData.alertData?.subTitle) ? alertData.alertData?.subTitle : ""
    var ButtonType = (alertData.alertData?.typeButton) ? alertData.alertData?.typeButton : ""
    return (
      alertData.showAlert && (
        <AlertView title={Title} subTitle={SubTitle} buttonType={ButtonType}
          handleOkay={(value) => {
            handelOkayButton()
          }} />
      )
    )
  }
  const handelOkayButton = () => {
    setAlertData({ showAlert: false })
  }
  const updateEmailAddress = (e) => {
    setEmail(e.target.value);
  }

  const updatePhoneNumber = (e) => {
    setPhoneNumber(e.target.value);
  }

  const validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setValidEmail(re.test(String(email).toLowerCase()))
  }


  return (
    <div className="Search-ContainerV2">
      {
        customAlert()
      }
      {
        spinner && (
          <SpinnerView />
        )

      }

      {showPDF && (
        <div className="transparentView">
          <div className="backGroundView">
            <div ref={ref} className="Base-ContainerV2">
              <div className='PDF-Container'>
                <img style={{ width: '142px', height: '28px', padding: '20px' }} src={gryd} className="App-logo" alt="logo" />
                <img style={{ width: '42px', height: '42px', padding: '10px' }} src={tick} className="App-logo" alt="logo" />
                <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                  <span style={{ fontSize: 16, color: "#00af84" }}>
                    Your hourly parking space has been booked!
                  </span>
                </div>
                <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                  <span style={{ fontSize: 10, color: "#71777d" }}>
                    This email is to confirm your recent booking.
                  </span>
                </div>
                <div style={{ width: '70%', height: '0.5px', backgroundColor: 'lightgrey', margin: '10px' }}></div>
                <div>
                  <div style={{ fontFamily: "Manrope", textAlign: "center", marginTop: '10px' }}>
                    <span style={{ fontSize: 12, color: "#00af84" }}>Reservation Details</span>
                  </div>
                  <div />
                </div>
                <div className='Detail-Container'>
                  <div className='Sub-Container'>
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking ID #</strong>
                      </swap>
                      <div style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px', }}>
                        {parkingId}
                      </div>
                    </div>
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking Spot #</strong>
                      </swap>
                      <div style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {parkingSpot}
                      </div>
                    </div>
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Location #</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {parkinglocation}
                      </swap>
                    </div>
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking Start Time</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {parkingStartTime}
                      </swap>
                    </div>
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking End Time</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {parkingEndTime}
                      </swap>
                    </div>
                    {/* <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking Time #</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>                Parking Time
                      </swap>
                    </div> */}
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>License Plate #</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {licensePlate}
                      </swap>
                    </div>
                  </div>
                </div>
                <div style={{ width: '70%', height: '0.5px', backgroundColor: 'lightgrey', margin: '10px' }}></div>
                <div>
                  <div style={{ fontFamily: "Manrope", textAlign: "center", marginTop: '10px' }}>
                    <span style={{ fontSize: 12, color: "#00af84" }}>Receipt</span>
                  </div>
                </div>
                <div className='Detail-Container'>
                  <div className='Sub-Container'>
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Payment Method</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {paymentMethod}
                      </swap>
                    </div>
                    {/* <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking Start Date #</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        Start Date
                      </swap>
                    </div> */}
                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Parking Fee</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {`$ ${parkingFee.toFixed(2)}`}
                      </swap>
                    </div>

                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>Transaction Fee</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {`$ ${transactionFee.toFixed(2)}`}
                      </swap>
                    </div>

                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>GST</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {`$ ${GST.toFixed(2)}`}
                      </swap>
                    </div>

                    <div className='row'>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontWeight: 800, fontStyle: 'inherit', textAlign: "inherit", fontSize: 12 }}>
                        <strong>TOTAL</strong>
                      </swap>
                      <swap style={{ marginTop: '3px', fontFamily: "Manrope", fontStyle: 'normal', textAlign: "inherit", fontSize: 12, width: '100px' }}>
                        {`$ ${total.toFixed(2)}`}
                      </swap>
                    </div>
                  </div>
                </div>
                {/* <div style={{ width: '70%', height: '0.5px', backgroundColor: 'lightgrey', margin: '10px' }}></div> */}
                {/* <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                  <span style={{ fontSize: 12, color: "#00af84" }}><strong>How Does Monthly Parking Work?</strong></span>
                </div> */}
                {/* <div style={{ fontFamily: "Manrope", textAlign: "center", width: '80%' }}>
                  <div style={{ fontSize: 12, color: 'black', fontStyle: 'normal' }}>Spots are reserved on a month-to-month basis. Monthly parking is automatically renewed on the last day of each month for the following month. If you do not wish to renew for the following month you can cancel your monthly parking subscription at any time before the final day of the month. Payments are processed on the last day of the month via your original payment method.</div>
                  <div style={{ fontSize: 12, color: 'black', fontStyle: 'normal', marginTop: '10px' }}>If you’re selected spot is available the following month you will be automatically renewed for the same spot. In the event that your spot is not available to rent the following month, we will notify you and prompt you to select a new spot for the next month. If there are no spots available that suit you, you can opt to cancel your monthly parking subscription.</div>
                </div> */}
                <div style={{ width: '70%', height: '0.5px', backgroundColor: 'lightgrey', margin: '10px' }}></div>
                <table
                  className="module"
                  role="module"
                  data-type="text"
                  border={0}
                  cellPadding={0}
                  cellSpacing={0}
                  width="100%"
                  style={{ tableLayout: "fixed" }}
                  data-muid="f2bcf88d-65af-45ff-8b48-677964677e47"
                  data-mc-module-version="2019-10-22"
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          textAlign: "Manrope"
                        }}
                        valign="top"
                        bgcolor=""
                        role="module-content"
                      >
                        <div>
                          <div style={{ fontFamily: "Manrope", textAlign: "center", marginTop: '10px' }}>
                            <span style={{ fontSize: 12 }}>Have questions?</span>
                          </div>
                          <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                            <span style={{ fontSize: 12 }}>Visit our </span>
                            <a href="https://help.parkwithgryd.com/">
                              <span style={{ fontSize: 12, color: '#00af84', textDecoration: 'none' }}>
                                <strong>GrydPark Support Site</strong>
                              </span>
                            </a>
                            <span style={{ fontSize: 12 }}>!</span>
                          </div>
                          <div />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{ padding: "10px" }} className='rowv3 '>
                  <td style={{ padding: "0px 5px" }} className="social-icon-column">
                    <a
                      role="social-icon-link"
                      href="https://www.facebook.com/gryddigital"
                      target="_blank"
                      alt="Facebook"
                      title="Facebook"
                      style={{
                        display: "inline-block",
                        backgroundColor: "#323232",
                        height: 16,
                        width: 16,
                        borderRadius: 2,
                        WebkitBorderRadius: 2,
                        MozBorderRadius: 2
                      }}
                    >
                      <img
                        role="social-icon"
                        alt="Facebook"
                        title="Facebook"
                        // src="https://mc.sendgrid.com/assets/social/white/facebook.png"
                        src={facebook}
                        style={{ height: 16, width: 16 }}
                        height={26}
                        width={26}
                      />
                    </a>
                  </td>
                  <td style={{ padding: "0px 5px" }} className="social-icon-column">
                    <a
                      role="social-icon-link"
                      href="https://www.instagram.com/gryd.com/"
                      target="_blank"
                      alt="Instagram"
                      title="Instagram"
                      style={{
                        display: "inline-block",
                        backgroundColor: "#323232",
                        height: 16,
                        width: 16,
                        borderRadius: 2,
                        WebkitBorderRadius: 2,
                        MozBorderRadius: 2
                      }}
                    >
                      <img
                        role="social-icon"
                        alt="Instagram"
                        title="Instagram"
                        // src="https://mc.sendgrid.com/assets/social/white/instagram.png"
                        src={instagram}
                        style={{ height: 16, width: 16 }}
                        height={26}
                        width={26}
                      />
                    </a>
                  </td>
                  <td style={{ padding: "0px 5px" }} className="social-icon-column">
                    <a
                      role="social-icon-link"
                      href="https://www.linkedin.com/company/gryd/"
                      target="_blank"
                      alt="LinkedIn"
                      title="LinkedIn"
                      style={{
                        display: "inline-block",
                        backgroundColor: "#323232",
                        height: 16,
                        width: 16,
                        borderRadius: 2,
                        WebkitBorderRadius: 2,
                        MozBorderRadius: 2
                      }}
                    >
                      <img
                        role="social-icon"
                        alt="LinkedIn"
                        title="LinkedIn"
                        // src="https://mc.sendgrid.com/assets/social/white/linkedin.png"
                        src={linkedin}
                        style={{ height: 16, width: 16 }}
                        height={26}
                        width={26}
                      />
                    </a>
                  </td>
                </div>
                <div>
                  <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                    <span style={{ color: "#71777d", fontSize: 10, fontWeight: 200 }}>
                      Copyright © 2020 GrydPark, All rights reserved.
                    </span>
                  </div>
                  <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                    <span style={{ color: "#71777d", fontSize: 10, fontWeight: 200 }}>
                      Please do not reply to this email.
                    </span>
                  </div>
                  <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                    <a
                      href="https://mc.sendgrid.com/dynamic-templates/d-ec9469e83a85403799233949dd2dfde2/version/b6e55535-9320-4d8f-bcd1-0b4221583627/editor/modules?moduleId=2059644b-84c9-488c-978f-f1e0d4752e41"
                      title='<span data-offset-key="e4tgt-0-0" style="text-decoration: underline; font-size: 10px; font-weight: 200;"><span data-text="true">Terms of Use</span></span>'
                    >
                      <span
                        style={{
                          textDecorationLine: "underline",
                          fontSize: 10,
                          fontWeight: 200,
                          color: '#00af84'
                        }}
                      >
                        Terms of Use
                      </span>
                    </a>
                    <span style={{ color: "#71777d", fontSize: 10, fontWeight: 200 }}>
                      {" "}
                      |{" "}
                    </span>
                    <a
                      href="https://mc.sendgrid.com/dynamic-templates/d-ec9469e83a85403799233949dd2dfde2/version/b6e55535-9320-4d8f-bcd1-0b4221583627/editor/modules?moduleId=2059644b-84c9-488c-978f-f1e0d4752e41"
                      title='<span data-offset-key="e4tgt-2-0" style="text-decoration: underline; font-size: 10px; font-weight: 200;"><span data-text="true">Privacy Policy</span></span>'
                    >
                      <span
                        style={{
                          textDecorationLine: "underline",
                          fontSize: 10,
                          fontWeight: 200,
                          color: '#00af84'
                        }}
                      >
                        Privacy Policy
                      </span>
                    </a>
                  </div>
                  <div style={{ fontFamily: "Manrope", textAlign: "center", }}>
                    <span style={{ color: "#71777d", fontSize: 10, fontWeight: 200 }}>
                      4B-488 Hargrave Street, Winnipeg, MB R3A 0X5, Canada
                    </span>
                  </div>
                  <div />
                </div>

                <div
                  data-role="module-unsubscribe"
                  className="module"
                  role="module"
                  data-type="unsubscribe"
                  style={{
                    fontFamily: "Manrope",
                    color: "#444444",
                    fontSize: 5,
                    lineHeight: 0,
                    padding: "5px 16px 16px 16px",
                    textAlign: "center"
                  }}
                  data-muid="e7eb2876-10b1-4f1f-b4cb-f2427f654398"
                >
                  <div className="Unsubscribe--addressLine" />
                  <p style={{ fontSize: 10, lineHeight: 0 }}>
                    <a
                      className="Unsubscribe--unsubscribeLink"
                      href="{{{unsubscribe}}}"
                      target="_blank"
                      style={{}}
                    >
                      Unsubscribe
                    </a>{" "}
                    -{" "}
                    <a
                      href="{{{unsubscribe_preferences}}}"
                      target="_blank"
                      className="Unsubscribe--unsubscribePreferences"
                      style={{}}
                    >
                      Unsubscribe Preferences
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <img style={{}} src={CheckMark}></img>
      <div>
        <p className="SearchTitle">Your Parking Has Started</p>
        <p style={{ color: "#323232", textAlign: "center" }} className="TitleLbl">You have reserved parking spot #{friendlySpotId}</p>
      </div>
      {/* <div>
        <p className="SearchTitle">{`Spot id : ${friendlySpotId}`}</p>
      </div> */}

      <div className="SubContainerSearchViewV2">
        <div>
          {/* {showDownload && (
            <>
              {email && (
                <TextField
                  id="email"
                  color="primary"
                  label="Email Address"
                  variant="outlined"
                  autoComplete="off"
                  value={email}
                  className="MatrialTextFieldFull"
                  fullWidth
                  onChange={(e) => updateEmailAddress(e)}
                />
              ) ||
                <TextField
                  id="phone"
                  label="Phone Number"
                  color="primary"
                  variant="outlined"
                  autoComplete="off"
                  value={phoneNumber}
                  className="MatrialTextFieldFull"
                  fullWidth
                  onChange={(e) => updatePhoneNumber(e)}
                  inputProps={{
                    inputMode: 'decimal',
                    maxLength: 11
                  }}
                />
              }
            </>
            )||(
              <TextField
                id="email"
                color="primary"
                label="Email Address"
                variant="outlined"
                autoComplete="off"
                value={email}
                className="MatrialTextFieldFull"
                fullWidth
                onChange={(e) => updateEmailAddress(e)}
              />)
          } */}


        </div>
        <div>
          {
            generateBillPDF()
          }
        </div>

        <div style={{ fontFamily: 'Manrope', fontSize: '17px', fontWeight: 400, textAlign: "center", marginTop: showDownload ? 10 : 20 }}>
          OR
        </div>
        <div>
          <p style={{ color: "#323232", textAlign: "center" }} className="TitleLbl">Get a copy of your receipt sent to you</p>
        </div>
        <div style={{ marginTop: '10px' }}>
          <TextField
            id="email"
            color="primary"
            label="Email Address"
            variant="outlined"
            autoComplete="off"
            value={email}
            className="MatrialTextFieldFull"
            fullWidth
            onChange={(e) => updateEmailAddress(e)}
          />
          {showDownload && (

            <p className="link-text" >{`Receipt sent`}</p>

          )}
          {/* {
            showDownload && (<div>
              {
                generateBillPDF()
              }
            </div>) || (<TextField
              id="phone"
              label="Phone Number"
              color="primary"
              variant="outlined"
              autoComplete="off"
              value={phoneNumber}
              className="MatrialTextFieldFull"
              fullWidth
              onChange={(e) => updatePhoneNumber(e)}
              inputProps={{
                inputMode: 'decimal',
                maxLength: 11
              }}
            />)
          } */}


        </div>
        {/* <div className="sendReceipt-button-view"> */}
        <div className="Next-ViewV2">
          <Button
            disabled={false}
            style={(phoneNumber || validEmail) ? { width: '100%', height: '50px', backgroundColor: '#00AF85', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' } : { width: '100%', height: '50px', backgroundColor: '#8A959E', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', borderRadius: '12px' }} variant="contained"
            onClick={() => {
              if (phoneNumber || validEmail) {
                setShowDownload(true);
              } else {
                setShowDownload(false);
              }
              if (validEmail) {
                SetSpinner(true)
                setTimeout(() => {

                }, 1000);
                addToAzureAppInsight({ User_Guid: "", Action: `send-receipt-email`, Page: "SendReceipt" });

                api.send_receipt_email({
                  "ReservationGuid": localStorage.getItem('Stored-reservationGuid'),
                  "RecipientMail": email
                }, {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
                }).then((sendReceiptResponse) => {
                  if (sendReceiptResponse.status == 200) {
                    //SetSpinner(false)
                  }


                })
                setTimeout(() => {
                  SetSpinner(false)
                }, 1000);

              } else if (phoneNumber) {
                SetSpinner(true)
                addToAzureAppInsight({ User_Guid: "", Action: `send-receipt-phone-number`, Page: "SendReceipt" });

                api.send_receipt_sms({
                  "ReservationGuid": localStorage.getItem('Stored-reservationGuid'),
                  "MobileNumber": parseInt(phoneNumber)
                }, {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization': 'Bearer ' + localStorage.getItem('Stored-AccessToken')
                }).then((sendReceiptResponse) => {
                  if (sendReceiptResponse.status == 200) {
                    //SetSpinner(false)
                  }


                })
                setTimeout(() => {
                  SetSpinner(false)
                }, 1000);
              }

            }}
          >Send Receipt</Button>
          <Button
            style={{ width: '100%', height: '50px', fontSize: "15px", fontFamily: 'Manrope', fontWeight: '700', marginTop: 10, borderColor: "#00AF85", color: "#00AF85", borderRadius: '12px' }} variant="outlined"
            onClick={
              () => {
                _props.handelClose()
                if (localStorage.getItem('last-booking-details')) {
                  let storedVehicledetails = JSON.parse(localStorage.getItem('last-booking-details'))
                  const VehicleDetails = {
                    propertyName: storedVehicledetails.propertyName,
                    make: storedVehicledetails.make,
                    model: storedVehicledetails.model,
                    color: storedVehicledetails.color,
                    licensePlate: storedVehicledetails.licensePlate,
                    propertyGuid: storedVehicledetails.propertyGuid,
                    curLatitude: storedVehicledetails.curLatitude,
                    curLogitude: storedVehicledetails.curLongitude,
                    phoneNumber: storedVehicledetails.phoneNumber
                  }
                  console.log("last booking store values redux", VehicleDetails)
                  addVehicle(storedVehicledetails);
                  var DurationDetails = {
                    Duration: 0,
                    DurationType: "Minutes"
                  }
                  addDuration(DurationDetails);
                 // console.log("localStorage.getItem('Stored-AccessToken')", localStorage.getItem('Stored-AccessToken'));
                  //console.log("state.user", state.user.VehicleDetails);
                  addToAzureAppInsight({ User_Guid: "", Action: `navigate-extend-via-send-receipt`, Page: "SendReceipt" });

                  navigate('/duration', {
                    state:
                    {
                      Via: 'Extend'
                    }
                  });
                } else {
                  addToAzureAppInsight({ User_Guid: "", Action: `error-navigate-extend-via-send-receipt`, Page: "SendReceipt" });
                  setAlertData({
                    showAlert: true, alertData: {
                      title: "GrydPark",
                      subTitle: " No active booking to extend ",
                    }
                  })
                }

                // var storedData = localStorage.getItem("last-booking-details");
                // if (storedData) {

                //   console.log("object storage", JSON.parse(storedData));
                //   const json = JSON.parse(storedData)
                //   const currentDateTime = moment().format("MM/DD/YYYY HH:mm");
                //   console.log("current time", currentDateTime);
                //   console.log("end time", json["end-time"]);
                //   console.log("diff", moment(currentDateTime, "MM/DD/YYYY HH:mm").isBefore(moment(json["end-time"], "MM/DD/YYYY HH:mm")));
                //   if (moment(currentDateTime, "MM/DD/YYYY HH:mm").isBefore(moment(json["end-time"], "MM/DD/YYYY HH:mm"))) {
                //     navigate('/duration', {
                //       state:
                //       {
                //         Via: 'Extend'
                //       }
                //     });
                //   } else {
                //     setAlertData({
                //       showAlert: true, alertData: {
                //         title: "GrydPark",
                //         subTitle: " All spots are booked. Please check another one. ",
                //       }
                //     })
                //   }
                //   }
              }
            }
          >Extend Parking</Button>
          <p className="agreementText">
            By completing this booking I understand that I am agreeing to the Parking Spot Rental Agreement and Terms of Service.</p>
        </div>
      </div>
    </div>
  )
}
export default SendReceipt