import React from "react";
import "./HeaderView.css";
import { Button } from "@mui/material";

//import logo from '../Images/gryd_logo.png';
import logo from '../../Images/gryd_logo.png';
import help from '../../Images/helpv2.png';

interface HeaderViewProps {

}
interface HeaderViewState {

}
class HeaderView extends React.Component<HeaderViewProps, HeaderViewState> {

  constructor(props: HeaderViewProps) {
    super(props);

  }

  render() {
    return (
      <div className="header-fixed-hight">
        <div style={{ position: 'fixed', zIndex: 3, backgroundColor: 'white' }} className="coloum-container">
          <div style={{ height: '70px', justifyContent: 'space-between', alignItems: 'center' }} className="row-container">
            <img src={logo} className="logo-icon" />
            <div style={{ padding: '10px' }} >
              <Button>
                <img src={help} className="help-icon"
                  onClick={() => {
                    window.open("https://help.parkwithgryd.com/");
                  }}
                />

              </Button>
            </div>
          </div>
          <div className="LineGradient">
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderView;
