import { json } from "stream/consumers";

export const HEADERS = () =>
  new Headers({
    "content-type": "application/json",

  });
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const StripKey = process.env.REACT_APP_STRIPE_KEY;

export default class API {
  showofflineError () {
    if (!navigator.onLine) {
      alert("Sorry, the network connectivity doesn't seem to be stable.")
    }
  }

  get_search_property = async (payload: any) => {
    this.showofflineError()
    return (
      await fetch(`${API_ENDPOINT}/spot/booking/guest/search-property`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(payload),

    })).json();
   
  }
  spot_availability = async (payload: any, headerData: any) => {
    // return (await fetch(`${API_ENDPOINT}/spot/booking/guest/spot-availability`, {
    //   headers: headerData,
    //   method: "POST",
    //   body: JSON.stringify(payload),

    // })).json();
    this.showofflineError()
    return  fetch(`${API_ENDPOINT}/spot/booking/guest/spot-availability`, {
      headers: headerData,
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(async res => res.status == 200 ? JSON.stringify({
      "status" : res.status,
      "response" : await res.json()
    }):JSON.stringify({
      "status" : res.status,
      "response" :await res.text()
    })
    )
  }

  property_availability = async (payload: any) => {
    // return (await fetch(`${API_ENDPOINT}/spot/booking/guest/property-availability`, {
    //   headers: HEADERS(),
    //   method: "POST",
    //   body: JSON.stringify(payload),

    // })).json();
    this.showofflineError()
    return  fetch(`${API_ENDPOINT}/spot/booking/guest/property-availability`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(async res => res.status == 200 ? JSON.stringify({
      "status" : res.status,
      "response" : await res.json()
    }):JSON.stringify({
      "status" : res.status,
      "response" :await res.text()
    })
    )
    
  
  }
  get_price_details = async (payload: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/booking/guest/get-price-details`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(payload),

    })).json();
  }
  get_vehicle_accesstoken = async (payload: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/guest-accestoken `, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(payload),

    })).json();

  }
  hold_spot = async (payload: any, headerData: any) => {
    // return (await fetch(`${API_ENDPOINT}/spot/booking/guest/hold-spot`, {
    //   headers: headerData,
    //   method: "POST",
    //   body: JSON.stringify(payload),

    // })).json();
    this.showofflineError()
    return  fetch(`${API_ENDPOINT}/spot/booking/guest/hold-spot`, {
      headers: headerData,
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(async res => res.status == 200 ? JSON.stringify({
      "status" : res.status,
      "response" : await res.json()
    }):JSON.stringify({
      "status" : res.status,
      "response" :await res.text()
    })
    )
    
  
  }
  initiate_payment = async (payload: any, headerData: any) => {
    this.showofflineError()
    var data = await fetch(`${API_ENDPOINT}/spot/booking/guest/initiate-payment`, {
      headers: headerData,
      method: "POST",
      body: JSON.stringify(payload),
    })
    if(data.status == 200){
      return data.json()
    }else{
      return 'non200'
    }
    
   
   
  }
  // initiate_payment = async (payload: any, headerData: any) => {
   
  //   return (await fetch(`${API_ENDPOINT}/spot/booking/guest/initiate-payment`, {
  //     headers: headerData,
  //     method: "POST",
  //     body: JSON.stringify(payload),
  //   })).json();
  // }
  add_payment_card = async (payload: any, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/user/add-payment-card/${payload.Token}`, {
      headers: headerData,
      method: "POST",
      body: "",
    })).json();
  }

  unhold_spot = async (UnholdSpot: string, headerData: any) => {
    this.showofflineError()
    const response = await fetch(`${API_ENDPOINT}/spot/booking/unhold-spot/${UnholdSpot}`, {
      headers: headerData,
      method: "POST",
    })
    return response.status
  }
  no_final_payment_status = async (paymentID: string, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/booking/guest/payment-status/${paymentID}`, {
      headers: headerData,
      method: "get",

    })).json();
  }
  payment_status = async (paymentID: string, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/booking/guest/payment-status/${paymentID}/final`, {
      headers: headerData,
      method: "get",

    })).json();
  }
  get_payment_details = async (reservationGuid: string, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/reservations/GetParkerReservationsPaymentHistoryV3/${reservationGuid}`, {
      headers: headerData,
      method: "get",

    })).json();
  }
  get_booking_details = async (reservationGuid: string, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/booking/guest/details/${reservationGuid}`, {
      headers: headerData,
      method: "get",

    })).json();

  }
  send_receipt_email = async (payload: any, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/booking/guest/send-receipt-email`, {
      headers: headerData,
      method: "POST",
      body: JSON.stringify(payload),

    })).json();
  }
  send_receipt_sms = async (payload: any, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/booking/guest/send-receipt-sms`, {
      headers: headerData,
      method: "POST",
      body: JSON.stringify(payload),

    })).json();
  }
  get_visitor_verification = async (propertyGuid: any, headerData: any) => {
    this.showofflineError()
    const res = await fetch(`${API_ENDPOINT}/spot/visitor-parking/mobile-verify/${propertyGuid}`, {
      headers: headerData,
      method: "get",
    });
    if(res.status == 200){
      return res.json();
    }else{
      return null
    }
  }
  
  visitor_verify_tenant =  async (payload: any) => {
    this.showofflineError()
   return  fetch(`${API_ENDPOINT}/spot/visitor-parking/mobile-tenant-verification`, {
      headers: HEADERS(),
      method: "POST",
      body: JSON.stringify(payload),
    })
    .then(async res => res.status == 200 ? JSON.stringify({
      "status" : res.status,
      "response" : await res.json()
    }):JSON.stringify({
      "status" : res.status,
      "response" :await res.text()
    })
    )
    
  }
  get_active_units = async (propertyGuid: any, headerData: any) => {
    this.showofflineError()
    return (await fetch(`${API_ENDPOINT}/spot/visitor-parking/get-active-units/${propertyGuid}`, {
      headers: headerData,
      method: "get",
    })).json();
  }
}



