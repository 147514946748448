import * as React from 'react';
import { Routes, Route, Link } from "react-router-dom";

interface ExtendParkingProps{

}
interface ExtendParkingState{

}

class ExtendParking extends React.Component<ExtendParkingProps, ExtendParkingState>{

    constructor(props: ExtendParkingProps){
        super(props);
    }
    public componentDidMount = () => {
        
    }
    public render() {
        return(<div className='extendParking'>
        
        </div>)
    }
}
export default ExtendParking;